import { useEffect } from 'react';

import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';

import { CheckboxGame, RadioGame } from 'components/activites/iterative-games';
import { useClasse } from 'lib/hooks';

const SummarySection = ({ questions, options, questionIndex }) => {
  const optionsWithAnswers = options.map((o) => ({
    ...o,
    answeredQuestions: questions
      .slice(0, questionIndex)
      .filter((q) => q.answerValue === o.value),
  }));

  const extraInfos = questions
    .slice(0, questionIndex)
    .filter((q) => q.extraInfo)
    .map((q) => q.extraInfo);

  return (
    <SummaryFrame mt={8} px={6}>
      {optionsWithAnswers.map((oa) => (
        <Text key={oa.value} my={2}>
          <b>{oa.fullName}</b> :{' '}
          {oa.answeredQuestions.map((q) => q.subject).join(', ')}
        </Text>
      ))}
      {extraInfos?.length > 0 &&
        extraInfos.map((info, i) => (
          <Text key={i}>
            <i>{info}</i>
          </Text>
        ))}
    </SummaryFrame>
  );
};

export const FormationsLieu = ({ allowNext }) => {
  const { classeIsAgro } = useClasse();

  const questionsAgro = [
    {
      subject: 'BTS/BTSA',
      answerValues: ['lycee', 'cfa'],
    },
    { subject: 'MC et CS', answerValues: ['lycee', 'cfa', 'prive'] },
    { subject: 'FCIL/SIL', answerValues: ['lycee', 'cfa'] },
    { subject: 'BUT', answerValues: ['universite'] },
    { subject: 'Licences générales', answerValues: ['universite'] },
    { subject: 'CPGE', answerValues: ['lycee'] },
  ];

  const questionsNonAgro = [
    {
      subject: 'BTS',
      answerValues: ['lycee', 'cfa'],
    },
    { subject: 'MC et FCIL', answerValues: ['lycee', 'cfa'] },
    { subject: 'BUT', answerValues: ['universite'] },
    { subject: 'Licences générales', answerValues: ['universite'] },
    { subject: 'CPGE', answerValues: ['lycee'] },
  ];

  const questions = classeIsAgro ? questionsAgro : questionsNonAgro;

  const options = [
    { shortName: 'Lycée', value: 'lycee' },
    { shortName: classeIsAgro ? 'CFA/CFPPA' : 'CFA', value: 'cfa' },
    { shortName: 'Université', value: 'universite' },
    { shortName: 'Ecole privée', value: 'prive' },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <CheckboxGame
      questions={questions}
      getOptions={() => options}
      onFinished={() => allowNext(true)}
      introSection={
        <Text textAlign={'center'}>
          Quels lieux d’étude correspondent aux formations suivantes ?
          (plusieurs réponses possibles).
        </Text>
      }
      getQuestionSection={(question) => (
        <Text>
          <i>
            Où sont enseignés les <b>{question.subject}</b> ?
          </i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame mt={5}>
            <UnorderedList>
              {questions.slice(0, questionIndex).map((q) => (
                <ListItem key={q.subject}>
                  <b>{q.subject} :</b>{' '}
                  {q.answerValues
                    .map((av) => options.find((o) => o.value === av).shortName)
                    .join(', ')}
                </ListItem>
              ))}
            </UnorderedList>
          </SummaryFrame>
        )
      }
    />
  );
};

export const LaBonneDefinition = ({ allowNext }) => {
  const { classeIsAgro } = useClasse();

  const questionsAgro = [
    {
      text: 'Qu’est ce qu’une MC ?',
      answerValue: '1',
      options: [
        {
          shortName: 'Mention Complémentaire',
          value: '1',
        },
        {
          shortName: 'Master en Comptabilité',
          value: '2',
        },
        {
          shortName: 'Magistère de Catégorie',
          value: '3',
        },
      ],
    },
    {
      text: 'Qu’est ce qu’une FCIL ?',
      answerValue: '2',
      options: [
        {
          shortName: 'Formation de Commerce International et Local',
          value: '1',
        },
        {
          shortName: 'Formation Complémentaire d’Initiative Locale',
          value: '2',
        },
        {
          shortName: 'Formation du Centre Institutionnel de Loisir',
          value: '3',
        },
      ],
    },
    {
      text: 'Qu’est ce qu’une SIL ?',
      answerValue: '1',
      options: [
        {
          shortName: 'Spécialisation d’Initiative Locale',
          value: '1',
        },
        {
          shortName: 'Spécificité Industrielle Locale',
          value: '2',
        },
        {
          shortName: 'Spécialité Initiale Locale',
          value: '3',
        },
      ],
    },
    {
      text: 'Qu’est ce qu’un BTSA ?',
      answerValue: '3',
      options: [
        {
          shortName: 'Brevet de Technique Supplémentaire Agricole',
          value: '1',
        },
        {
          shortName: 'Bachelor de Technicien Supérieur Agricole',
          value: '2',
        },
        {
          shortName: 'Brevet de Technicien Supérieur Agricole',
          value: '3',
        },
      ],
    },
    {
      text: 'Qu’est ce qu’un CS ?',
      answerValue: '1',
      options: [
        {
          shortName: 'Certificat de Spécialisation',
          value: '1',
        },
        {
          shortName: 'Classe Spécifique',
          value: '2',
        },
        {
          shortName: 'Centre Spécialisé',
          value: '3',
        },
      ],
    },
    {
      text: 'Qu’est ce qu’un BUT ?',
      answerValue: '1',
      options: [
        {
          shortName: 'Bachelor Universitaire de Technologie',
          value: '1',
        },
        {
          shortName: 'Brevet Universitaire Touristique',
          value: '2',
        },
        {
          shortName: 'Bachelor Universitaire Territorial',
          value: '3',
        },
      ],
    },
    {
      text: 'Qu’est ce qu’une CPGE ?',
      answerValue: '2',
      options: [
        {
          shortName: 'Centre Périphérique de Groupe Énergétique',
          value: '1',
        },
        {
          shortName: 'Classe Préparatoire aux Grandes Écoles',
          value: '2',
        },
        {
          shortName: 'Classe Préparatoire aux Grandes Économies',
          value: '3',
        },
      ],
    },
    {
      text: classeIsAgro
        ? 'Qu’est qu’un CFA et un CFPPA ?'
        : 'Qu’est qu’un CFA  ?',
      answerValue: '3',
      options: [
        {
          shortName: classeIsAgro ? 'Des formations' : 'Une formation',
          value: '1',
        },
        {
          shortName: classeIsAgro
            ? 'Des sites internet pour les études'
            : 'Un site internet pour les études',
          value: '2',
        },
        {
          shortName: classeIsAgro
            ? 'Des centres qui proposent des formations en alternance'
            : 'Un centre qui propose des formations en alternance',
          value: '3',
        },
      ],
      questionCFA: true,
    },
  ];
  const questionsNonAgro = [
    {
      text: 'Qu’est ce qu’une MC ?',
      answerValue: '1',
      options: [
        {
          shortName: 'Mention Complémentaire',
          value: '1',
        },
        {
          shortName: 'Master en Comptabilité',
          value: '2',
        },
        {
          shortName: 'Magistère de Catégorie',
          value: '3',
        },
      ],
    },
    {
      text: 'Qu’est ce qu’une FCIL ?',
      answerValue: '2',
      options: [
        {
          shortName: 'Formation de Commerce International et Local',
          value: '1',
        },
        {
          shortName: 'Formation Complémentaire d’Initiative Locale',
          value: '2',
        },
        {
          shortName: 'Formation du Centre Institutionnel de Loisir',
          value: '3',
        },
      ],
    },
    {
      text: 'Qu’est ce qu’un BTS ?',
      answerValue: '3',
      options: [
        {
          shortName: 'Brevet de Technique Suplémentaire',
          value: '1',
        },
        {
          shortName: 'Bachelor de Technicien Supérieur',
          value: '2',
        },
        {
          shortName: 'Brevet de Technicien Supérieur',
          value: '3',
        },
      ],
    },
    {
      text: 'Qu’est ce qu’un BUT ?',
      answerValue: '1',
      options: [
        {
          shortName: 'Bachelor Universitaire de Technologie',
          value: '1',
        },
        {
          shortName: 'Brevet Universitaire Touristique',
          value: '2',
        },
        {
          shortName: 'Bachelor Universitaire Territorial',
          value: '3',
        },
      ],
    },
    {
      text: 'Qu’est ce qu’une CPGE ?',
      answerValue: '2',
      options: [
        {
          shortName: 'Centre Périphérique de Groupe Énergétique',
          value: '1',
        },
        {
          shortName: 'Classe Préparatoire aux Grandes Écoles',
          value: '2',
        },
        {
          shortName: 'Classe Préparatoire aux Grandes Économies',
          value: '3',
        },
      ],
    },
    {
      text: classeIsAgro
        ? 'Qu’est qu’un CFA et un CFPPA ?'
        : 'Qu’est qu’un CFA  ?',
      answerValue: '3',
      options: [
        {
          shortName: classeIsAgro ? 'Des formations' : 'Une formation',
          value: '1',
        },
        {
          shortName: classeIsAgro
            ? 'Des sites internet pour les études'
            : 'Un site internet pour les études',
          value: '2',
        },
        {
          shortName: classeIsAgro
            ? 'Des centres qui proposent des formations en alternance'
            : 'Un centre qui propose des formations en alternance',
          value: '3',
        },
      ],
      questionCFA: true,
    },
  ];

  const questions = classeIsAgro ? questionsAgro : questionsNonAgro;

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <RadioGame
      isVertical
      questions={questions}
      getOptions={(question) => question?.options}
      onFinished={() => allowNext(true)}
      introSection={
        <Text textAlign={'center'}>
          Sélectionne la bonne définition des acronymes de formations
        </Text>
      }
      getQuestionSection={(question) => (
        <Text mt={0}>
          <i>{question.text}</i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame mt={8}>
            <UnorderedList>
              {questions.slice(0, questionIndex).map((q) => (
                <>
                  <ListItem key={q.text}>
                    <b>{q.text} :</b>{' '}
                    {q.options.find((c) => c.value === q.answerValue).shortName}
                  </ListItem>
                  {q.questionCFA && (
                    <ListItem>
                      <b>CFA :</b> Centre de formation d'apprentis
                    </ListItem>
                  )}
                  {q.questionCFA && classeIsAgro && (
                    <ListItem>
                      <b>CFPPA :</b> Centre de formation professionnelle et de
                      promotion agricole (propose des formations en
                      apprentissage spécialisées dans le domaine agricole)
                    </ListItem>
                  )}
                </>
              ))}
            </UnorderedList>
          </SummaryFrame>
        )
      }
    />
  );
};

export const DureeFormations = ({ allowNext }) => {
  const { classeIsAgro } = useClasse();

  const questions = [
    {
      subject: classeIsAgro ? 'MC, CS, FCIL/SIL' : 'MC et FCIL',
      answerValue: '1',
      isPlural: true,
    },
    {
      subject: `BTS${classeIsAgro ? '/BTSA' : ''} et classes préparatoires`,
      answerValue: '2',
      isPlural: true,
    },
    { subject: 'licences générales', answerValue: '3', isPlural: true },
  ];

  const options = [
    {
      fullName: "Formations d'1 an ou moins",
      shortName: '1 an ou moins',
      value: '1',
    },
    {
      fullName: 'Formations de 2 ans',
      shortName: '2 ans',
      value: '2',
    },
    {
      fullName: 'Formations de 3 ans',
      shortName: '3 ans',
      value: '3',
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <RadioGame
      questions={questions}
      getOptions={() => options}
      onFinished={() => allowNext(true)}
      introSection={
        <Text textAlign={'center'}>
          Dans cette activité, tu dois indiquer la <b>durée des formations</b>.
        </Text>
      }
      getQuestionSection={(question) => (
        <Text>
          <i>
            {question.subject === 'licences générales'
              ? 'Les '
              : question.isPlural
              ? 'Les formations '
              : 'La formation '}{' '}
            <b>{question?.subject}</b> {question.isPlural ? 'durent' : 'dure'}
            ...
          </i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummarySection
            questions={questions}
            options={options}
            questionIndex={questionIndex}
          />
        )
      }
    />
  );
};

export const FormatDEnseignement = ({ allowNext }) => {
  const { classeIsAgro } = useClasse();

  const questions = [
    {
      subject: `MC, ${classeIsAgro ? 'CS,' : ''} FCIL${
        classeIsAgro ? '/SIL' : ''
      } et BTS${classeIsAgro ? '/BTSA' : ''}`, //'MC',
      answerValue: 'pratique',
      isPlural: true,
    },
    {
      subject: 'BUT',
      answerValue: 'les-deux',
      extraInfo:
        'Le BUT est une formation à la fois théorique et pratique, elle mêle des enseignements généraux, théoriques et professionnels avec des périodes de mise en pratique via des stages.',
    },
    { subject: 'Licence générale', answerValue: 'theorique' },
  ];

  const options = [
    {
      fullName: 'Formations pratiques',
      shortName: 'Pratique (avec période de formation en milieu professionnel)',
      value: 'pratique',
    },
    {
      fullName: 'Formations théoriques',
      shortName: 'Théorique',
      value: 'theorique',
    },
    {
      fullName: 'Formations pratiques et théoriques',
      shortName: 'Les deux',
      value: 'les-deux',
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <RadioGame
      questions={questions}
      getOptions={() => options}
      onFinished={() => allowNext(true)}
      isVertical
      introSection={
        <Text>
          Dans cette activité, tu dois dire si les formations sont{' '}
          <b>pratiques</b>, <b>théoriques</b>, ou bien <b>les deux</b>.
        </Text>
      }
      infoSection={
        <Box bg="gray.100" p={5} borderRadius={4} mb={8}>
          <UnorderedList my={0}>
            <ListItem>
              Une formation <b>pratique</b> prépare les étudiants de manière
              concrète à la pratique d’un métier via des périodes de stage plus
              longues et l’enseignement de matières professionnelles.
            </ListItem>
            <ListItem>
              Une formation <b>théorique</b> permet aux étudiants d’approfondir
              leurs connaissances via des enseignements généraux et théoriques.
            </ListItem>
            <ListItem>
              Une formation <b>pratique et théorique</b> mêle enseignants
              généraux, théoriques et professionnels avec des périodes de mise
              en pratique via des stages.
            </ListItem>
          </UnorderedList>
        </Box>
      }
      getQuestionSection={(question) => (
        <Text>
          <i>
            Dans {question.isPlural ? 'les formations ' : 'la formation '}{' '}
            <b>{question?.subject}</b> l’enseignement est plus ?
          </i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummarySection
            questions={questions}
            options={options}
            questionIndex={questionIndex}
          />
        )
      }
    />
  );
};

export const TypeDEnseignement = ({ allowNext }) => {
  const { classeIsAgro } = useClasse();

  const questions = [
    {
      subject:
        'Parmi les formations suivantes, quelles sont les deux formations où l’accompagnement par les enseignants est moins important que dans ta classe actuelle ?',
      answerValues: ['licence', 'BUT'],
    },
  ];

  const options = [
    {
      fullName: 'MC',
      shortName: classeIsAgro ? 'MC, CS' : 'MC',
      value: 'MC',
    },
    {
      fullName: 'FCIL',
      shortName: classeIsAgro ? 'FCIL/SIL' : 'FCIL',
      value: 'FCIL',
    },
    {
      fullName: 'BTS',
      shortName: classeIsAgro ? 'BTS/BTSA' : 'BTS',
      value: 'BTS',
    },
    {
      fullName: 'Licence générale',
      shortName: 'Licence générale',
      value: 'licence',
    },
    {
      fullName: 'BUT',
      shortName: 'BUT',
      value: 'BUT',
    },
    {
      fullName: 'CPGE',
      shortName: 'CPGE',
      value: 'CPGE',
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <CheckboxGame
      questions={questions}
      getOptions={() => options}
      onFinished={() => allowNext(true)}
      infoSection={
        <Box bg="gray.100" px={6} py={5} borderRadius={4} mb={8}>
          <Text>
            Une formation <b>plus encadrée</b> a un format d'enseignement proche
            du fonctionnement du lycée.
          </Text>
          <Text>
            Une formation <b>moins encadrée</b> a un format d'enseignement qui
            demande plus d'autonomie de ta part car les élèves sont moins guidés
            par les professeurs.
          </Text>
        </Box>
      }
      getQuestionSection={(question) => (
        <Text>
          <i>{question.subject}</i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame>
            L’accompagnement de l’enseignant va dépendre en partie de la taille
            de la classe, moins les élèves sont nombreux, plus l’enseignant
            pourra accorder du temps d’accompagnement à chaque élève :
            <UnorderedList>
              <ListItem>
                Dans les formations courtes (MC, FCIL, BTS,...) les classes sont
                souvent comme au lycée (30 élèves)
              </ListItem>
              <ListItem>
                En BUT, les cours varient entre un format de classe comme au
                lycée et des cours en amphithéâtre (avec plus d’élèves)
              </ListItem>
              <ListItem>
                En licence, les cours varient entre des cours magistraux dans
                des amphithéâtres avec beaucoup d’étudiants et des cours en TD
                (travaux dirigés) proche d’un format de classe comme au lycée.
                Il y a souvent moins d’heures de cours, cela demande donc
                beaucoup plus de travail personnel en autonomie.
              </ListItem>
            </UnorderedList>
          </SummaryFrame>
        )
      }
    />
  );
};

export const FormationsAlternance = ({ allowNext }) => {
  const { classeIsAgro } = useClasse();

  const questions = [
    { subject: classeIsAgro ? 'BTS/BTSA' : 'BTS', answerValue: 'alternance' },
    { subject: 'Licence générale', answerValue: 'alternance' },
    { subject: 'MC', answerValue: 'alternance' },
    { subject: 'BUT', answerValue: 'alternance' },
    { subject: 'FCIL', answerValue: 'alternance' },
    {
      subject: 'CPGE',
      answerValue: 'pas-alternance',
      extraInfo:
        'Il existe de l’alternance dans quasiment toutes les filières, sauf les classes préparatoires. Faire une licence générale en alternance c’est encore rare mais possible.',
    },
  ];

  const options = [
    {
      fullName: 'Formations avec alternance possible',
      shortName: 'Alternance possible',
      value: 'alternance',
    },
    {
      fullName: 'Formations sans alternance',
      shortName: "Pas d'alternance",
      value: 'pas-alternance',
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <RadioGame
      questions={questions}
      getOptions={() => options}
      onFinished={() => allowNext(true)}
      introSection={
        <Text textAlign={'center'}>
          Dans cette activité, tu dois dire si les formations peuvent être
          suivies en <b>alternance</b> ou <b>pas</b>.
        </Text>
      }
      getQuestionSection={(question) => (
        <Text>
          <i>
            La formation <b>{question?.subject}</b> peut-elle être suivie en
            alternance ?
          </i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummarySection
            questions={questions}
            options={options}
            questionIndex={questionIndex}
          />
        )
      }
    />
  );
};

export const FormationsParcoursup = ({ allowNext }) => {
  const { classeIsAgro } = useClasse();

  const questions = [
    {
      subject: classeIsAgro ? 'MC ou CS' : 'MC',
      answerValue: 'parcoursup',
      extraInfo: `Certaines demandes d’admission aux MC${
        classeIsAgro ? ' et CS' : ''
      } se font directement auprès des établissements`,
    },
    {
      subject: classeIsAgro ? 'FCIL ou SIL' : 'FCIL',
      answerValue: 'parcoursup',
      extraInfo: `Une partie des FCIL sont sur Parcoursup. Pour les autres FCIL${
        classeIsAgro ? ' et les SIL' : ''
      }, les inscriptions se font directement auprès des établissements.`,
    },
    { subject: 'CPGE', answerValue: 'parcoursup' },
    { subject: classeIsAgro ? 'BTS/BTSA' : 'BTS', answerValue: 'parcoursup' },
    { subject: 'BUT', answerValue: 'parcoursup' },
    { subject: 'Licence générale', answerValue: 'parcoursup' },
  ];

  const options = [
    {
      fullName: 'Formations avec candidature via Parcoursup',
      shortName: 'Via Parcoursup',
      value: 'parcoursup',
    },
    {
      fullName: 'Formations hors Parcoursup',
      shortName: 'Hors Parcoursup, directement auprès des établissements',
      value: 'non',
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <RadioGame
      isVertical
      questions={questions}
      getOptions={() => options}
      onFinished={() => allowNext(true)}
      introSection={
        <Text textAlign={'center'}>
          Dans cette activité, tu dois dire si les candidatures aux formations
          doivent être faites via <b>Parcoursup</b> ou <b>pas</b>.
        </Text>
      }
      getQuestionSection={(question) => (
        <Text>
          <i>
            Une candidature à la formation <b>{question?.subject}</b>{' '}
            s'effectue...
          </i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummarySection
            questions={questions}
            options={options}
            questionIndex={questionIndex}
          />
        )
      }
    />
  );
};

export const LicencesGenOuPro = ({ allowNext, goNext }) => {
  const questions = [
    { subject: 'Elle dure 3 ans', answerValue: 'generale' },
    {
      subject: 'Elle dure 1 an après un Bac +2',
      answerValue: 'professionnelle',
    },
    {
      subject: 'Elle favorise une insertion professionnelle rapide',
      answerValue: 'professionnelle',
    },
  ];

  const options = [
    {
      fullName: 'Licence générale',
      shortName: 'Licence générale',
      value: 'generale',
    },
    {
      fullName: 'Licence professionnelle',
      shortName: 'Licence professionnelle',
      value: 'professionnelle',
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <RadioGame
      isVertical
      questions={questions}
      getOptions={() => options}
      onFinished={() => {
        allowNext(true);
        goNext();
      }}
      introSection={
        <Text textAlign={'center'}>
          Coche le type de licence qui correspond aux caractéristiques
        </Text>
      }
      getQuestionSection={(question) => (
        <Text fontWeight={'bold'}>
          <i>{question?.subject}</i>
        </Text>
      )}
    />
  );
};
