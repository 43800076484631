import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Center,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Text,
} from '@chakra-ui/react';
import { CheckCircleIcon, ExternalIcon, WarningIcon } from '@inspire/ui/icons';

import ProfTips from 'components/activites/ProfTips';
import { handleErrors } from 'lib/errors';
import {
  useMyProfile,
  useCreateCvdesignrUser,
  useUser,
  useCvdesignrFlow,
} from 'lib/hooks';
import PageSpinner from '@inspire/ui/chakra/layout/PageSpinner';

const GreenCheckMark = () => (
  <Icon
    color="green.500"
    as={CheckCircleIcon}
    verticalAlign="middle"
    mr={1}
    boxSize={4}
    pos="relative"
    top="-1px"
  />
);

const MainContent = ({ url }) => (
  <Center flexDirection="column">
    <Text textAlign="center" maxW="700px">
      <GreenCheckMark /> Tu peux maintenant créer des CV et des lettres de
      motivation sur CVDesignR ! N’oublie pas de télécharger régulièrement tes
      documents pour ne pas les perdre à la fin de l’année lorsque la licence
      expirera.
    </Text>
    <Button
      rightIcon={<Icon as={ExternalIcon} />}
      as="a"
      target="_blank"
      href={url}
    >
      Ouvrir CVDesignR
    </Button>
    <Text>
      Tu pourras inviter ton professeur à les corriger ou les commenter.
    </Text>
  </Center>
);

// Actually this situation can only happen for lyceens, not profs. Keeping
// the copy switch in case we set it up for profs in the future.
const AuthLinkContent = ({ isProf, email, url }) => (
  <>
    <Text>
      {isProf ? 'Vous semblez' : 'Tu sembles'} déjà avoir un compte CVDesignR
      enregistré avec l'adresse <b>{email}</b>. {isProf ? 'Cliquez' : 'Clique'}{' '}
      sur ce bouton pour autoriser Inspire à accéder à{' '}
      {isProf ? 'votre' : 'ton'} compte CVDesignR.
    </Text>
    <Center my={8}>
      <Button
        rightIcon={<Icon as={ExternalIcon} />}
        as="a"
        target="_blank"
        href={url}
        textAlign="center"
        fontStyle="normal"
      >
        Connecter Inspire à mon compte CVDesignR
      </Button>
    </Center>
  </>
);

const ProfNeedsToSetUp = () => (
  <Center my={10}>
    <Box>
      <Icon
        as={WarningIcon}
        mr={2}
        verticalAlign="middle"
        pos="relative"
        top="-1px"
      />
      Ton professeur doit ouvrir cette activité avant que tu puisses accéder à
      CVDesignR.
    </Box>
  </Center>
);

const CreateAccount = ({
  createCvdesignrUser,
  isCreatingCvdesignrUser,
  email,
  isProf,
}) => {
  const [passwordValue, setPasswordValue] = useState('');

  return (
    <Box my={5}>
      {isProf ? (
        <Text>
          INSPIRE vous donne gratuitement une licence annuelle au site CVDesignR
          qui permet à vos élèves de créer des CV et des lettres de motivation à
          partir de modèles à compléter. En tant que membre d’une équipe
          pédagogique, vous pourrez consulter les documents créés par vos élèves
          et les commenter. Afin d'utiliser CVDesignR il vous faut un compte
          CVDesignR.
        </Text>
      ) : (
        <Text>
          INSPIRE te donne gratuitement une licence annuelle au site CVDesignR
          qui te permet de créer des CV et des lettres de motivation à partir de
          modèles à compléter.
        </Text>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createCvdesignrUser(passwordValue);
        }}
      >
        <Text>
          Il n'existe pas de compte CVDesignR associé à l'adresse email{' '}
          <b>{email}</b>.
          <br />
          Nous pouvons{' '}
          <b>
            créer {isProf ? 'votre' : 'ton'} compte CVDesignR pour{' '}
            {isProf ? 'vous' : 'toi'}
          </b>{' '}
          avec cette adresse email. Il {isProf ? 'vous' : 'te'} suffit de
          choisir un mot de passe.
        </Text>
        <Center fontStyle="normal">
          <FormControl isRequired maxW="300px">
            <FormLabel>Mot de passe</FormLabel>
            <Input
              bgColor="white"
              isRequired
              pattern="^(?=.{8,50})(?=.*\d).*$"
              onChange={(e) => setPasswordValue(e.target.value)}
              value={passwordValue}
              placeholder={`${
                isProf ? 'Choisissez' : 'Choisis'
              } un mot de passe`}
              type="password"
            />
            <FormHelperText>
              8 caractères minimum, dont un chiffre et une lettre.
            </FormHelperText>
            <Center>
              <Button mt={4} type="submit" isLoading={isCreatingCvdesignrUser}>
                Créer mon compte CVDesignR
              </Button>
            </Center>
          </FormControl>
        </Center>
      </form>
    </Box>
  );
};

const Cvdesignr = ({ allowNext }) => {
  const { isLyceen, isProf } = useUser();
  const { myProfile } = useMyProfile();

  const { cvdesignrFlow, isCvdesignrFlowLoading, refetchCvdesignrFlow } =
    useCvdesignrFlow({ refetchOnMount: true });

  const { createCvdesignrUser, isCreatingCvdesignrUser } =
    useCreateCvdesignrUser({
      onSuccess: () => refetchCvdesignrFlow(),
      onError: ({ errors }) => handleErrors(errors),
    });

  useEffect(() => {
    allowNext(Boolean(cvdesignrFlow?.kind === 'MAGIC_LINK'));
  }, [cvdesignrFlow]);

  return (
    <>
      <ProfTips mb={3}>
        {isCvdesignrFlowLoading ? (
          <PageSpinner />
        ) : (
          <>
            {cvdesignrFlow?.kind === 'CVDESIGNR_ACCOUNT_CREATION' && (
              <CreateAccount
                createCvdesignrUser={createCvdesignrUser}
                isCreatingCvdesignrUser={isCreatingCvdesignrUser}
                email={myProfile.email}
                isProf={isProf}
              />
            )}
            {cvdesignrFlow?.kind === 'AUTH_LINK' && (
              <AuthLinkContent
                isProf={isProf}
                url={cvdesignrFlow?.value}
                email={myProfile.email}
              />
            )}
            {cvdesignrFlow?.kind === 'MAGIC_LINK' && (
              <Text>
                Vos élèves pourront eux aussi créer facilement et gratuitement
                un compte CVDesignR directement dans cette activité, et verront
                un bouton de connexion comme celui présenté ci-dessous. Ils
                n’ont plus qu'à indiquer un mot de passe. Vous pouvez également
                cliquer sur ce bouton pour vous connecter à votre compte en tant
                qu'accompagnant sur CVDesignR et accéder aux documents de votre
                classe et à leurs demandes de correction. Vos élèves doivent
                télécharger régulièrement leurs documents pour ne pas les perdre
                à la fin de l’année lorsque la licence expirera.
              </Text>
            )}
            {cvdesignrFlow?.kind === 'INCOMPATIBLE_EXISTING_PROF_ACCOUNT' && (
              <Text>
                Vous semblez déjà avoir un compte CVDesignR enregistré avec
                l'adresse <b>{myProfile.email}</b>. Malheureusement nous ne
                pouvons pas utiliser ce compte pour gérer les CV de votre classe
                sur CVDesignR pour le moment.
              </Text>
            )}
          </>
        )}
      </ProfTips>
      {isLyceen && isCvdesignrFlowLoading ? (
        <PageSpinner />
      ) : (
        <>
          {cvdesignrFlow?.kind === 'AUTH_LINK' && isLyceen && (
            <AuthLinkContent
              isProf={isProf}
              url={cvdesignrFlow?.value}
              email={myProfile.email}
            />
          )}
          {cvdesignrFlow?.kind === 'PROF_SETUP_NEEDED' && <ProfNeedsToSetUp />}
          {cvdesignrFlow?.kind === 'MAGIC_LINK' && (
            <MainContent url={cvdesignrFlow?.value} />
          )}
          {cvdesignrFlow?.kind === 'CVDESIGNR_ACCOUNT_CREATION' && isLyceen && (
            <CreateAccount
              createCvdesignrUser={createCvdesignrUser}
              isCreatingCvdesignrUser={isCreatingCvdesignrUser}
              email={myProfile.email}
              isProf={isProf}
            />
          )}
        </>
      )}
    </>
  );
};

export default Cvdesignr;
