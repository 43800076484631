import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';

import { MixedGame } from 'components/activites/iterative-games';
import ProfTips from 'components/activites/ProfTips';
import ClickableImage from 'components/general/ClickableImage';
import { useClasse } from 'lib/hooks';

import InfographieAgroSrc from '../../../../../public/img/infographies/InfographieSchemaPostBacVoieAgricole.png';
import InfographieNonAgroSrc from '../../../../../public/img/infographies/InfographieSchemaPostBacVoiePro.png';

export const QuiSuisJeEtudesSuperieurs = ({ allowNext }) => {
  const { classeIsAgro } = useClasse();

  const questionsNonAgro = [
    {
      text: 'Je dure 3 ans, je suis préparée dans un Institut Universitaire de Technologie, je peux être faite en alternance et je suis à la fois théorique et pratique. Je suis ?',
      answerValues: ['but', 'bachelor universitaire de technologie'],
      displayAnswer: 'Bachelor Universitaire de Technologie (BUT)',
      inputType: 'text',
    },
    {
      text: 'Je suis accessible après un BTS, je dure 1 an, je prépare de manière concrète à un métier, je peux être faite en alternance. Je suis ?',
      answerValues: ['licence pro'],
      displayAnswer: 'Licence professionnelle',
      inputType: 'text',
    },
    {
      text: 'Je suis une formation spécialisée qui vise à acquérir des compétences professionnelles bien déterminées dans un secteur local qui recrute. Je dure quelques mois. Je suis ?',
      answerValues: ['fcil', "formation complementaire d'initiative locale"],
      displayAnswer: 'Formation Complémentaire d’Initiative Locale (FCIL)',
      inputType: 'text',
    },
    {
      text: 'Je peux être intégrée directement après le bac, en passant un concours et/ou un oral et/ou sur dossier. Je suis le plus souvent privée. Je peux être spécialisée dans différents domaines. Je suis ?',
      answerValues: ['ecole post-bac', 'ecole post bac'],
      displayAnswer: 'École post-bac',
      inputType: 'text',
    },
    {
      text: 'Je suis la preuve qu’il n’y a pas qu’une seule voie pour atteindre un diplôme. Avec moi on peut passer d’un type de formation à un autre sans repasser par Parcoursup. Je peux avoir lieu en cours d’année ou après une année validée. Je suis une forme de réorientation. Je suis ?',
      answerValues: ['passerelle'],
      displayAnswer: 'Une passerelle',
      extraInfo:
        'Il existe des passerelles dans quasiment tous les cursus, cela permet de se réorienter soit en cours d’année soit à la fin de l’année scolaire.',
      inputType: 'radio',
      options: [
        {
          fullName: 'Un passage',
          shortName: 'Un passage',
          value: 'passage',
        },
        {
          fullName: 'Une passerelle',
          shortName: 'Une passerelle',
          value: 'passerelle',
        },
        {
          fullName: 'Un corridor',
          shortName: 'Un corridor',
          value: 'corridor',
        },
      ],
    },
  ];

  const questionsAgro = [
    {
      text: 'Je dure 3 ans, je suis préparée dans un Institut Universitaire de Technologie, je peux être faite en alternance et je suis à la fois théorique et pratique. Je suis ?',
      answerValues: ['but', 'bachelor universitaire de technologie'],
      displayAnswer: 'Bachelor Universitaire de Technologie (BUT)',
      inputType: 'text',
    },
    {
      text: 'Je suis accessible après un BTS, je dure 1 an, je prépare de manière concrète à un métier, je peux être faite en alternance. Je suis ?',
      answerValues: ['licence pro'],
      displayAnswer: 'Licence professionnelle',
      inputType: 'text',
    },
    {
      text: 'Je suis une formation spécialisée qui vise à acquérir des compétences professionnelles bien déterminées dans un secteur local qui recrute. Je dure quelques mois. Je suis ?',
      answerValues: ['fcil', 'sil'],
      displayAnswer:
        'Formation Complémentaire d’Initiative Locale (FCIL), Spécialisation d’Initiative Locale (SIL)',
      inputType: 'checkbox',
      options: [
        {
          fullName: 'FCIL',
          shortName: 'FCIL',
          value: 'fcil',
        },
        {
          fullName: 'SIL',
          shortName: 'SIL',
          value: 'sil',
        },
        {
          fullName: 'BTSA',
          shortName: 'BTSA',
          value: 'btsa',
        },
      ],
    },
    {
      text: 'Je peux être intégrée directement après le bac, en passant un concours et/ou un oral et/ou sur dossier. Je suis le plus souvent privée. Je peux être spécialisée dans différents domaines. Je suis ?',
      answerValues: ['ecole post-bac', 'ecole post bac'],
      displayAnswer: 'École post-bac',
      inputType: 'text',
    },
    {
      text: 'Je suis une formation dispensée en alternance qui permet d’acquérir des compétences dans un champ professionnel précis et délivrant un titre professionnel du ministère chargé de l’agriculture. Je suis ?',
      answerValues: [
        'certificat de specialisation',
        'cs',
        'certificat specialisation',
      ],
      displayAnswer: 'Certificat de specialisation',
      inputType: 'text',
    },
    {
      text: 'Je suis la preuve qu’il n’y a pas qu’une seule voie pour atteindre un diplôme. Avec moi on peut passer d’un type de formation à un autre sans repasser par Parcoursup. Je peux avoir lieu en cours d’année ou après une année validée. Je suis une forme de réorientation. Je suis ? Un passage, une passerelle, un corridor ?',
      answerValues: ['passerelle'],
      displayAnswer: 'Une passerelle',
      extraInfo:
        'Il existe des passerelles dans quasiment tous les cursus, cela permet de se réorienter soit en cours d’année soit à la fin de l’année scolaire.',
      inputType: 'radio',
      options: [
        {
          fullName: 'Un passage',
          shortName: 'Un passage',
          value: 'passage',
        },
        {
          fullName: 'Une passerelle',
          shortName: 'Une passerelle',
          value: 'passerelle',
        },
        {
          fullName: 'Un corridor',
          shortName: 'Un corridor',
          value: 'corridor',
        },
      ],
    },
  ];

  const questions = classeIsAgro ? questionsAgro : questionsNonAgro;

  return (
    <MixedGame
      introSection={
        <Box textAlign="center" mt={3}>
          <ClickableImage
            src={classeIsAgro ? InfographieAgroSrc : InfographieNonAgroSrc}
            href={
              classeIsAgro
                ? 'img/infographies/InfographieSchemaPostBacVoieAgricole.png'
                : 'img/infographies/InfographieSchemaPostBacVoiePro.png'
            }
            alt="Infographie études supérieurs"
          />
          <Text mt={3}>Devine de quelle formation il s’agit.</Text>
        </Box>
      }
      isVertical
      questions={questions}
      onFinished={() => allowNext(true)}
      getQuestionSection={(question) => (
        <Text mt={0}>
          <i>{question.text}</i>
        </Text>
      )}
      getOptions={(question) => question.options ?? []}
      getSummarySection={(_, questionIndex) => {
        const extraInfos = questions
          .slice(0, questionIndex)
          .filter((q) => q.extraInfo)
          .map((q) => q.extraInfo);
        return (
          <>
            {questionIndex !== 0 && (
              <SummaryFrame mt={6}>
                <UnorderedList spacing={6}>
                  {questions.slice(0, questionIndex).map((q) => (
                    <ListItem key={q.text}>
                      <b>{q.text} :</b> {q.displayAnswer}
                    </ListItem>
                  ))}
                </UnorderedList>
                {extraInfos?.length > 0 &&
                  extraInfos.map((info, i) => (
                    <Text key={i}>
                      <i>{info}</i>
                    </Text>
                  ))}
              </SummaryFrame>
            )}
            <ProfTips mt={8}>
              <Text>
                Les passerelles sont possibles dans des domaines d’études
                relativement proches (par exemple, on ne passera pas directement
                d’un BTS relation client à une 3ème année de BUT chimie).
              </Text>
              <Text>Exemples de passerelle dans le supérieur :</Text>
              <UnorderedList>
                <ListItem>
                  Après un BTS validé on peut rejoindre une licence en passent
                  directement en 3ème année s’il s’agit d’une formation dans le
                  même domaine.
                </ListItem>
                <ListItem>
                  Après 2 an en BUT il est parfois possible d’intégrer une école
                  en passant un concours accessible au bac + 2.
                </ListItem>
              </UnorderedList>
            </ProfTips>
          </>
        );
      }}
    />
  );
};
