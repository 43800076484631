import {
  CreateLyceenMutation,
  CreateLyceenMutationVariables,
  CreateProfMutation,
  CreateProfMutationVariables,
  ForgotPasswordMutation,
  GenerateTokenMutation,
  JoinClasseMutation,
  RemoveEleveFromClasseMutation,
  ResetPasswordMutation,
  SetActiviteBacProMutation,
  UpdateClasseMutation,
  UpdateModuleMutation,
  VerifyEmailMutation,
  useCreateLyceenMutation,
  useCreateProfMutation,
  useForgotPasswordMutation,
  useGenerateTokenMutation,
  useJoinClasseMutation,
  useRemoveEleveFromClasseMutation,
  useResetPasswordMutation,
  useSetActiviteBacProMutation,
  useSetMyProfileMutation,
  useUpdateClasseMutation,
  useUpdateModuleMutation,
  useVerifyEmailMutation,
  ResendVerificationEmailMutation,
  useResendVerificationEmailMutation,
  DeleteClasseMutation,
  useDeleteClasseMutation,
  useCreateClasseMutation,
  CreateClasseMutation,
  ChangeCurrentClasseMutation,
  useChangeCurrentClasseMutation,
  TriggerAlgoMutation,
  useTriggerAlgoMutation,
  useResetDemoMutation,
  ResetDemoMutation,
  SubmitPisteFeedbackMutation,
  SubmitPisteFeedbackMutationVariables,
  useSubmitPisteFeedbackMutation,
  useSetBilanModuleMutation,
  SetBilanModuleMutation,
  QuitClasseMutation,
  useQuitClasseMutation,
  SendMessageMutation,
  useSendMessageMutation,
  ImportElevesMutation,
  useImportElevesMutation,
  SetMyProfileMutation,
  JobreadyTokenMutation,
  useJobreadyTokenMutation,
  AddProfMutation,
  useAddProfMutation,
  RemoveProfMutation,
  useRemoveProfMutation,
  useSetLyceeStudyYearMutation,
  SetLyceeStudyYearMutation,
  CreateCvdesignrUserMutation,
  useCreateCvdesignrUserMutation,
  RateAlgoResultMutation,
  useRateAlgoResultMutation,
  SetStateOfMindMutation,
  useSetStateOfMindMutation,
  SetEclaireurMobiliteGroupMutation,
  useSetEclaireurMobiliteGroupMutation,
  useCountLyceenAlgoMutation,
  CountLyceenAlgoMutation,
} from 'lib/generated';
import { MutationOptions } from 'lib/hooks/react-query-types';

export const useSetMyProfile = (
  options?: MutationOptions<SetMyProfileMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useSetMyProfileMutation(options);
  return {
    setMyProfile: (input: any) => mutate({ input }),
    setMyProfileAsync: (input: any) => mutateAsync({ input }),
    isSettingMyProfile: isLoading,
    ...rest,
  };
};

export const useSetStateOfMind = (
  options?: MutationOptions<SetStateOfMindMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useSetStateOfMindMutation(options);
  return {
    setStateOfMind: (input: any) => mutate({ input }),
    setStateOfMindAsync: (input: any) => mutateAsync({ input }),
    isSettingStateOfMind: isLoading,
    ...rest,
  };
};

export const useSendMessage = (
  options?: MutationOptions<SendMessageMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useSendMessageMutation(options);
  return {
    sendMessage: (
      threadId: string,
      content: string,
      introQuestionKey?: string,
      introQuestionCategory?: string
    ) => mutate({ threadId, content, introQuestionKey, introQuestionCategory }),
    sendMessageAsync: (
      threadId: string,
      content: string,
      introQuestionKey?: string,
      introQuestionCategory?: string
    ) =>
      mutateAsync({
        threadId,
        content,
        introQuestionKey,
        introQuestionCategory,
      }),
    isSendingMessage: isLoading,
    ...rest,
  };
};

export const useSetActivite = (
  options?: MutationOptions<SetActiviteBacProMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useSetActiviteBacProMutation(options);
  return {
    setActivite: (activiteId: string, status: string) =>
      mutate({ activiteId, status }),
    setActiviteAsync: (
      activiteId: string,
      status: string,
      moduleActivites?: string[]
    ) => mutateAsync({ activiteId, status, moduleActivites }),
    isSettingActivite: isLoading,
    ...rest,
  };
};

export const useImportEleves = (
  classeId: string,
  options?: MutationOptions<ImportElevesMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useImportElevesMutation(options);
  return {
    importEleves: (eleveIds: string[]) => mutate({ classeId, eleveIds }),
    importElevesAsync: (eleveIds: string[]) =>
      mutateAsync({ classeId, eleveIds }),
    isImportingEleves: isLoading,
    ...rest,
  };
};

export const useSetBilanModule = (
  moduleId: string,
  options?: MutationOptions<SetBilanModuleMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useSetBilanModuleMutation(options);
  return {
    setBilanModule: (questions: { id: string; answer: number }[]) =>
      mutate({ moduleId, questions }),
    setBilanModuleAsync: (questions: { id: string; answer: number }[]) =>
      mutateAsync({ moduleId, questions }),
    isSettingBilanModule: isLoading,
    ...rest,
  };
};

export const useCreateClasse = (
  options?: MutationOptions<CreateClasseMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useCreateClasseMutation(options);
  return {
    createClasse: (input: any) => mutate({ input }),
    createClasseAsync: (input: any) => mutateAsync({ input }),
    isCreatingClasse: isLoading,
    ...rest,
  };
};

export const useUpdateClasse = (
  options?: MutationOptions<UpdateClasseMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useUpdateClasseMutation(options);
  return {
    updateClasse: (classeId: string, input: any) => mutate({ classeId, input }),
    updateClasseAsync: (classeId: string, input: any) =>
      mutateAsync({ classeId, input }),
    isUpdatingClasse: isLoading,
    ...rest,
  };
};

export const useDeleteClasse = (
  id: string,
  options?: MutationOptions<DeleteClasseMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useDeleteClasseMutation(options);
  return {
    deleteClasse: () => mutate({ id }),
    deleteClasseAsync: () => mutateAsync({ id }),
    isDeletingClasse: isLoading,
    ...rest,
  };
};

export const useQuitClasse = (
  id: string,
  options?: MutationOptions<QuitClasseMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useQuitClasseMutation(options);
  return {
    quitClasse: () => mutate({ id }),
    quitClasseAsync: () => mutateAsync({ id }),
    isQuittingClasse: isLoading,
    ...rest,
  };
};

export const useJoinClasse = (
  options?: MutationOptions<JoinClasseMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useJoinClasseMutation(options);
  return {
    joinClasse: (token: string) => mutate({ token }),
    joinClasseAsync: (token: string) => mutateAsync({ token }),
    isJoiningClasse: isLoading,
    ...rest,
  };
};

export const useGenerateToken = (
  options?: MutationOptions<GenerateTokenMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useGenerateTokenMutation(options);
  return {
    generateToken: (classeId: string) => mutate({ classeId }),
    generateTokenAsync: (classeId: string) => mutateAsync({ classeId }),
    isGeneratingToken: isLoading,
    ...rest,
  };
};

export const useForgotPassword = (
  options?: MutationOptions<ForgotPasswordMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useForgotPasswordMutation(options);
  return {
    forgotPassword: (email: string) => mutate({ email }),
    forgotPasswordAsync: (email: string) => mutateAsync({ email }),
    isForgotPasswordLoading: isLoading,
    ...rest,
  };
};

export const useResetPassword = (
  options?: MutationOptions<ResetPasswordMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useResetPasswordMutation(options);
  return {
    resetPassword: (
      newPassword: { digest: string; algorithm: string },
      token: string
    ) => mutate({ newPassword, token }),
    resetPasswordAsync: (
      newPassword: { digest: string; algorithm: string },
      token: string
    ) => mutateAsync({ newPassword, token }),
    isresetPasswordLoading: isLoading,
    ...rest,
  };
};

export const useResendVerificationEmail = (
  options?: MutationOptions<ResendVerificationEmailMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useResendVerificationEmailMutation(options);
  return {
    resendVerificationEmail: () => mutate(null),
    resendVerificationEmailAsync: () => mutateAsync(null),
    isResendVerificationEmailLoading: isLoading,
    ...rest,
  };
};

export const useRemoveEleveFromClasse = (
  options?: MutationOptions<RemoveEleveFromClasseMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useRemoveEleveFromClasseMutation(options);
  return {
    removeEleveFromClasse: (eleveId: string, classeId: string) =>
      mutate({ eleveId, classeId }),
    removeEleveFromClasseAsync: (eleveId: string, classeId: string) =>
      mutateAsync({ eleveId, classeId }),
    isRemovingEleveFromClasse: isLoading,
    ...rest,
  };
};

export const useAddProf = (options?: MutationOptions<AddProfMutation>) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useAddProfMutation(options);
  return {
    addProf: (profId: string, classeId: string) => mutate({ profId, classeId }),
    addProfAsync: (profId: string, classeId: string) =>
      mutateAsync({ profId, classeId }),
    isAddingProf: isLoading,
    ...rest,
  };
};

export const useRemoveProf = (
  options?: MutationOptions<RemoveProfMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useRemoveProfMutation(options);
  return {
    removeProf: (profId: string, classeId: string) =>
      mutate({ profId, classeId }),
    removeProfAsync: (profId: string, classeId: string) =>
      mutateAsync({ profId, classeId }),
    isRemovingProf: isLoading,
    ...rest,
  };
};

export const useUpdateModule = (
  options?: MutationOptions<UpdateModuleMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useUpdateModuleMutation(options);
  return {
    updateModule: (classeId: string, moduleConfig: any) =>
      mutate({ classeId, moduleConfig }),
    updateModuleAsync: (classeId: string, moduleConfig: any) =>
      mutateAsync({ classeId, moduleConfig }),
    isUpdatingModule: isLoading,
    ...rest,
  };
};

export const useVerifyEmail = (
  options?: MutationOptions<VerifyEmailMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useVerifyEmailMutation(options);
  return {
    verifyEmail: (token: string) => mutate({ token }),
    verifyEmailAsync: (token: string) => mutateAsync({ token }),
    isVerifyEmailLoading: isLoading,
    ...rest,
  };
};

export const useCreateProf = (
  options?: MutationOptions<CreateProfMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useCreateProfMutation(options);
  return {
    createProf: (prof: CreateProfMutationVariables) => mutate(prof),
    createProfAsync: (prof: CreateProfMutationVariables) => mutateAsync(prof),
    isCreatingProf: isLoading,
    ...rest,
  };
};

export const useCreateLyceen = (
  options?: MutationOptions<CreateLyceenMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useCreateLyceenMutation(options);
  return {
    createLyceen: (lyceen: CreateLyceenMutationVariables) => mutate(lyceen),
    createLyceenAsync: (lyceen: CreateLyceenMutationVariables) =>
      mutateAsync(lyceen),
    isCreatingLyceen: isLoading,
    ...rest,
  };
};

export const useChangeCurrentClasse = (
  options?: MutationOptions<ChangeCurrentClasseMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useChangeCurrentClasseMutation(options);
  return {
    changeCurrentClasse: (id: string) => mutate({ id }),
    changeCurrentClasseAsync: (id: string) => mutateAsync({ id }),
    isChangingCurrentClasse: isLoading,
    ...rest,
  };
};

export const useJobreadyToken = (
  options?: MutationOptions<JobreadyTokenMutation>
) => {
  const { data, isLoading, ...rest } = useJobreadyTokenMutation(options);
  return {
    jobreadyToken: data?.jobreadyToken,
    isJobreadyTokenLoading: isLoading,
    ...rest,
  };
};

export const useTriggerAlgo = (
  classeHasModules: boolean,
  options?: MutationOptions<TriggerAlgoMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useTriggerAlgoMutation(options);
  return {
    triggerAlgo: () => mutate({ classeHasModules }),
    triggerAlgoAsync: () => mutateAsync({ classeHasModules }),
    isTriggeringAlgo: isLoading,
    ...rest,
  };
};

export const useSubmitPisteFeedback = (
  options?: MutationOptions<SubmitPisteFeedbackMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useSubmitPisteFeedbackMutation(options);
  return {
    submitPisteFeedback: ({ input }: SubmitPisteFeedbackMutationVariables) =>
      mutate({ input }),
    submitPisteFeedbackAsync: ({
      input,
    }: SubmitPisteFeedbackMutationVariables) => mutateAsync({ input }),
    isSubmittingPisteFeedback: isLoading,
    ...rest,
  };
};

export const useSetLyceeStudyYear = (
  options?: MutationOptions<SetLyceeStudyYearMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useSetLyceeStudyYearMutation(options);
  return {
    setLyceeStudyYear: ({
      niveau,
      filiere,
      filiereBacPro,
      filiereTechno,
    }: {
      niveau: string;
      filiere?: string;
      filiereBacPro?: string;
      filiereTechno?: string;
    }) => mutate({ niveau, filiere, filiereBacPro, filiereTechno }),
    setLyceeStudyYearAsync: ({
      niveau,
      filiere,
      filiereBacPro,
      filiereTechno,
    }: {
      niveau: string;
      filiere?: string;
      filiereBacPro?: string;
      filiereTechno?: string;
    }) => mutateAsync({ niveau, filiere, filiereBacPro, filiereTechno }),
    isSettingLyceeStudies: isLoading,
    ...rest,
  };
};

export const useResetDemo = (options?: MutationOptions<ResetDemoMutation>) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useResetDemoMutation(options);
  return {
    resetDemo: () => mutate(null),
    resetDemoAsync: () => mutateAsync(null),
    isResettingDemo: isLoading,
    ...rest,
  };
};

export const useCreateCvdesignrUser = (
  options?: MutationOptions<CreateCvdesignrUserMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useCreateCvdesignrUserMutation(options);
  return {
    createCvdesignrUser: (password?: string) =>
      mutate({ cvdesignrPassword: password }),
    createCvdesignrUserAsync: (password?: string) =>
      mutateAsync({ cvdesignrPassword: password }),
    isCreatingCvdesignrUser: isLoading,
    ...rest,
  };
};

export const useRateAlgoResult = (
  pisteId: string,
  options?: MutationOptions<RateAlgoResultMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useRateAlgoResultMutation(options);
  return {
    rateAlgoResult: (rating: number) => mutate({ pisteId, rating }),
    rateAlgoResultAsync: (rating: number) => mutateAsync({ pisteId, rating }),
    isRatingAlgoResult: isLoading,
    ...rest,
  };
};

export const useEclaireurMobiliteGroup = (
  options?: MutationOptions<SetEclaireurMobiliteGroupMutation>
) => {
  const { mutate, mutateAsync, isLoading, ...rest } =
    useSetEclaireurMobiliteGroupMutation(options);
  return {
    setEclaireurMobiliteGroup: (hasBeenClicked: boolean) =>
      mutate({ hasBeenClicked }),
    setEclaireurMobiliteGroupAsync: (hasBeenClicked: boolean) =>
      mutateAsync({ hasBeenClicked }),
    isSayingHi: isLoading,
    ...rest,
  };
};

export const useCountLyceenAlgo = (
  options?: MutationOptions<CountLyceenAlgoMutation>
) => {
  const { mutate, mutateAsync } = useCountLyceenAlgoMutation(options);
  return {
    countLyceenAlgoMutation: (lyceenAlgoCounter: string) =>
      mutate({ lyceenAlgoCounter }),
    countLyceenAlgoMutationAsync: (lyceenAlgoCounter: string) =>
      mutateAsync({ lyceenAlgoCounter }),
  };
};
