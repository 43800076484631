import {
  ACTIVITE_CONTENT_TYPE_VIDEO,
  ACTIVITE_CONTENT_TYPE_FORM,
  ACTIVITE_CONTENT_TYPE_GAME,
  ACTIVITE_CONTENT_TYPE_ORAL,
} from '@inspire/data/static/activites';
import InfoIcon from '@inspire/ui/assets/undraw/information.svg';
import ComprendreParcoursupIcon from '@inspire/ui/assets/undraw/remote.svg';
import ConnaitreEntrepriseIcon from '@inspire/ui/assets/undraw/building.svg';
import MetiersIcon from '@inspire/ui/assets/undraw/mirror.svg';
import ApprentissageIcon from '@inspire/ui/assets/undraw/apprentissage.svg';
import PersonDocumentIcon from '@inspire/ui/assets/undraw/person-document.svg';
import CompetencesAcquisesIcon from '@inspire/ui/assets/undraw/progress.svg';
import MondeProIcon from '@inspire/ui/assets/undraw/prep.svg';
import DebatIcon from '@inspire/ui/assets/undraw/debate.svg';
import {
  FormatDEnseignement,
  FormationsAlternance,
  TypeDEnseignement,
  FormationsParcoursup,
  DureeFormations,
  FormationsLieu,
  LaBonneDefinition,
  LicencesGenOuPro,
} from 'components/activites/premiere/sinformer-etudes-sup/principales-formations-post-bac';
import {
  PhotoLanguage,
  TrierLogementsEtudiants,
  VieEtudiante,
  Crous,
} from 'components/activites/premiere/sinformer-etudes-sup/VieEtudiante';
import {
  EtapesOrientation,
  GererMonProjet,
  InfographieParcoursup,
} from 'components/activites/premiere/comprendre-parcoursup/temps-orientation';
import {
  OrganigrammeEntreprise,
  QuizzEntreprise,
  TemoignagesPro,
  VideoDecouvrirEntreprise,
} from 'components/activites/premiere/connaitre-lentreprise/decouvrir-lentreprise';
import {
  AlternancesQuestionsOrales,
  ApprofondirNotionAlternance,
  DebatAlternance,
} from 'components/activites/premiere/decouvrir-alternance-et-apprentissage/point-alternance';
import {
  DefinitionSoftSkills,
  DecrisExperienceSoftSkills,
  SelectSoftSkills,
  SelectDifficultesSoftSkills,
  DecrisActionsSoftSkills,
  DecrisAmeliorationsSoftSkills,
  SelectRessentiSoftSkills,
  MyRecapSoftSkills,
  PlanActionObjectif,
  PlanActionEntreprendre,
  PlanActionTemps,
  MyRecapPlanAction,
  IdentifierSoftSkills,
} from 'components/activites/premiere/connaitre-mes-competences-acquises/mieux-se-connaitre';
import {
  CommunicationNonVerbale,
  ListCompetences,
  MyRecapCompetences,
  RappelCompetences,
  SimulationEntretien,
  SimulationEntretienFeedback,
} from 'components/activites/premiere/preparer-entretiens/atelier-code-pro';
import {
  DefinitionApprentissage,
  InfographieChiffresApprentissage,
  InfographieEtapesAlternance,
  LesEtapesAvantApprentissage,
  TemoignageEric,
  TemoignageNaomi,
  TemoignagePaolandrea,
} from 'components/activites/premiere/decouvrir-alternance-et-apprentissage/alternance-voie-porteuse';
import { QuiSuisJeEtudesSuperieurs } from 'components/activites/premiere/sinformer-etudes-sup/QuiSuisJeEtudesSuperieurs';
import VideoDecouverteMetiers from 'components/activites/premiere/decouvrir-des-metiers/VideosDecouverteMetiers';
import {
  InfographieConseilEntretien,
  TemoignagesAnecdotes,
  TémoignagesProRH,
} from 'components/activites/premiere/preparer-entretiens/conseils-entretien';
import { ParcoursLineaire } from 'components/activites/premiere/connaitre-mes-competences-acquises/ParcoursLineaire';
import { BeneficesAlternance } from 'components/activites/premiere/connaitre-mes-competences-acquises/BeneficesAlternance';
import FichesMetiers from 'components/activites/premiere/decouvrir-des-metiers/FichesMetiers';
import { makeBilanActivite } from 'lib/activites';
import Cvdesignr from 'components/activites/Cvdesignr';
import QuestionnaireIcon from '@inspire/ui/assets/undraw/questions.svg';
import {
  Etudes,
  Attitude,
  Debouches,
  Encouragement,
  Finances,
  IntroQuestionnaire,
  LieuDeVie,
  Continue,
  AvenirProfessionnel1,
  AvenirProfessionnel2,
  Qualites,
  PrevisionPostBac,
  MatieresTroncCommun,
  MatieresSpes,
} from 'components/activites/premiere/decouvrir-pistes-davenir/QuestionnairePremiere';
import AlgoProgress from 'components/activites/premiere/decouvrir-pistes-davenir/AlgoProgress';
import {
  DecouvertePisteConclusion,
  DecouvertePisteConsignes,
  PisteRecoForm,
} from 'components/activites/premiere/decouvrir-pistes-davenir/decouverte-pistes';
import { ModuleType } from 'types';

// const hasFinishedQuestionnaire = {
//   condition: ({ myBacProActivites }) =>
//     myBacProActivites?.find(
//       (a) => a.activiteId === 'mon-questionnaire' && a.status === 'completed'
//     ),
//   reason:
//     'Tu dois compléter l\'activité "Mon questionnaire" avant de débuter cette activité.',
//   profReason:
//     'Vos élèves doivent compléter le module "Découvrir des pistes d’avenir" pour débloquer ce module.',
// };

export const DECOUVRIR_PISTES_AVENIR_ID = 'decouvrir-pistes-davenir';
const SINFORMER_ETUDES_SUP_ID = 'sinformer-etudes-sup';
const COMPRENDRE_PARCOURSUP_ID = 'comprendre-parcoursup';
const CONNAITRE_ENTREPRISE_ID = 'connaitre-lentreprise';
export const DECOUVRIR_METIERS_ID = 'decouvrir-des-metiers';
const DECOUVRIR_ALTERNANCE_APPRENTISSAGE_ID =
  'decouvrir-alternance-et-apprentissage';
const CONNAITRE_MES_COMPETENCES_ACQUISES_ID =
  'connaitre-mes-competences-acquises';
const PREPARER_ENTRETIENS_ID = 'preparer-entretiens';
const CVDESIGNR_PREMIERE = 'cvdesignr-premiere';

const modules: ModuleType[] = [
  {
    theme: 'etudes-sup',
    moduleId: DECOUVRIR_PISTES_AVENIR_ID,
    title: 'Découvrir des pistes d’avenir',
    icon: QuestionnaireIcon,
    intro:
      "Après avoir répondu au questionnaire, tu découvriras des pistes d'avenir potentielles adaptées à ton parcours personnel.",
    profDescription:
      'Les élèves remplissent un questionnaire qui leur permettra d’avoir des pistes d’avenir personnalisées afin de leur montrer les possibilités qui s’offrent à eux par rapport à leur profil que soit la poursuite d’études après le bac ou l’insertion professionnelle. Vous aurez accès aux réponses fournies par vos élèves.',
    objectifPedagogique:
      'En ayant des pistes d’avenir proposées en adéquation avec les réponses au questionnaire, les élèves pourront prioriser leurs prises d’informations sur les pistes les plus susceptibles de leur correspondre.',
    isMandatory: true,
    activites: [
      {
        title: 'Mon questionnaire',
        activiteId: 'mon-questionnaire',
        duration: 20,
        profDescription:
          'L’activité a pour but de fournir aux lycéens, une fois le questionnaire rempli, une liste de pistes potentielles vers lesquelles ces derniers peuvent s’orienter. Ces pistes se déclinent sous forme de contenus informatifs traitant d’études supérieures ou d’insertion professionnelle.',
        description:
          "Une fois le questionnaire complété, tu découvriras les pistes d'avenir potentielles qui te seront proposées en fonction de tes réponses.",
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        steps: [
          { title: 'On va commencer !', component: IntroQuestionnaire },
          {
            title: 'Poursuite des études',
            component: PrevisionPostBac,
            hasOwnFooter: true,
          },
          {
            title: 'Lieu de vie et trajet',
            component: LieuDeVie,
            hasOwnFooter: true,
          },
          {
            title: 'Situation financière',
            component: Finances,
            hasOwnFooter: true,
          },
          { title: 'Débouchés', component: Debouches, hasOwnFooter: true },
          { title: 'On continue !', component: Continue, hasOwnFooter: true },
          { title: 'Tout va bien ?', component: Encouragement },
          {
            title: 'Avenir professionnel 1/2',
            component: AvenirProfessionnel1,
            hasOwnFooter: true,
          },
          {
            title: 'Avenir professionnel 2/2',
            component: AvenirProfessionnel2,
            hasOwnFooter: true,
          },
          { title: 'Qualités', component: Qualites, hasOwnFooter: true },
          {
            title: 'Matières',
            component: MatieresTroncCommun,
            hasOwnFooter: true,
          },
          { title: 'Matières', component: MatieresSpes, hasOwnFooter: true },
          { title: 'Études', component: Etudes, hasOwnFooter: true },
          {
            title: 'Attitude en classe',
            component: Attitude,
            hasOwnFooter: true,
          },
        ],
      },
      {
        title: 'Mes pistes recommandées',
        activiteId: 'mes-pistes-recommandees',
        duration: 20,
        description:
          'Cette activité te permet de découvrir des pistes d’avenir qui te sont proposées à la suite de tes réponses au questionnaire d’aide à l’orientation.',
        profDescription:
          'Cette activité permet aux lycéens de s’informer sur les pistes d’avenir qui leur ont été recommandées après avoir rempli leur questionnaire d’orientation.',
        objectifPedagogique:
          'L’objectif de ce module est de présenter quelques pistes d’avenir, de réfléchir autour de ces propositions et voir si celles-ci sont conformes ou pas à ce que les lycéens envisagent actuellement.',
        // requirements: [hasFinishedQuestionnaire],
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        steps: [
          {
            title: 'Découverte des pistes',
            component: AlgoProgress,
            hasOwnFooter: true,
          },
          {
            title: 'Découverte des pistes',
            component: DecouvertePisteConsignes,
            hasOwnFooter: true,
          },
          {
            title: 'Découverte des pistes',
            component: PisteRecoForm,
            hasOwnFooter: true,
          },
          {
            title: 'Découverte des pistes',
            component: DecouvertePisteConclusion,
          },
        ],
      },
      makeBilanActivite(DECOUVRIR_PISTES_AVENIR_ID),
    ],
    bilanQuestions: [
      {
        id: 'citer-une-piste-avenir',
        text: "Je peux citer une piste d'avenir qui m'intéresse.",
      },
      {
        id: 'citer-avantages-inconvenients-piste',
        text: 'Je peux citer les avantages et inconvénients de cette piste.',
      },
    ],
  },
  {
    theme: 'etudes-sup',
    moduleId: SINFORMER_ETUDES_SUP_ID,
    title: 'S’informer et se préparer aux études supérieures',
    icon: InfoIcon,
    intro:
      'Ce module te permettra de te projeter dans les études supérieures et la vie étudiante.',
    profDescription:
      'Ce module a pour but de découvrir les principales formations post-bac et leur fonctionnement, ainsi que de permettre aux élèves de commencer à se projeter dans les études supérieures.',
    objectifPedagogique:
      'Permettre aux élèves de tester et d’enrichir leurs connaissances des principales formations post-bac et de la vie étudiante.',
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: 'Les principales formations post-bac',
        activiteId: 'principales-formations-post-bac',
        duration: 20,
        profDescription:
          'L’activité a pour but de présenter les informations clés des principales filières post-bac : durée, type d’encadrement, lieu de formation, type d’enseignements.',
        description:
          'Cette activité te permettra de découvrir les principales filières post-bac et d’avoir des informations sur leur durée, le type d’encadrement ou encore les lieux de formation.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_GAME }],
        steps: [
          {
            title: 'La bonne définition',
            component: LaBonneDefinition,
          },
          {
            title: 'Les formations et leur lieu d’étude',
            component: FormationsLieu,
          },
          {
            title: 'Durée des formations',
            component: DureeFormations,
          },
          {
            title: "Format d'enseignement",
            component: FormatDEnseignement,
          },
          {
            title: "Type d'encadrement d'enseignement",
            component: TypeDEnseignement,
          },
          {
            title: 'Formations en alternance',
            component: FormationsAlternance,
          },
          {
            title: 'Formations via Parcoursup',
            component: FormationsParcoursup,
          },
          {
            title: 'Licence générale ou professionnelle ?',
            component: LicencesGenOuPro,
          },
        ],
      },
      {
        title: 'Le qui suis-je des études supérieures',
        activiteId: 'qui-suis-je-etudes-superieures',
        duration: 10,
        profDescription:
          'A travers un quiz, les élèves testent leurs connaissances sur les études supérieures en s’aidant d’une infographie.',
        description:
          'Cette activité te permettra de tester tes connaissances sur les études supérieures.',
        component: QuiSuisJeEtudesSuperieurs,
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_GAME }],
      },
      {
        title: 'La vie étudiante',
        activiteId: 'la-vie-etudiante',
        duration: 15,
        profDescription:
          'Cette activité permet aux lycéens d’identifier les dépenses courantes d’un étudiant et les aides existantes.',
        description:
          'Cette activité te permettra de mieux te projeter dans la vie étudiante en identifiant les dépenses typiques d’un étudiant et les aides auxquelles il peut avoir droit.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
          { type: ACTIVITE_CONTENT_TYPE_GAME },
        ],
        steps: [
          {
            title: 'Photolangage des étudiants',
            component: PhotoLanguage,
            profInstructions:
              'Exemples de dépenses : Frais de scolarité, Nourriture, Transport, Logement, Abonnements (internet, téléphone, électricité, etc.), Santé, Loisirs, Matériels scolaires.',
          },
          {
            title: 'Les logements pour étudiants',
            component: VieEtudiante,
            profInstructions:
              'Demandez aux élèves de donner des exemples de types de logement pour les étudiants. Exemples de réponses : Famille, Logement en résidence étudiante universitaire, Logement intergénérationnel, Colocation, Studio...',
          },
          {
            title: 'Trier les logements du - cher au + cher',
            component: TrierLogementsEtudiants,
            profInstructions:
              'Demandez aux élèves de trier ces logements du moins cher au plus cher et d’indiquer des tarifs approximatifs en fonction de leur région.',
          },
          {
            title: 'CROUS',
            component: Crous,
          },
        ],
      },
      makeBilanActivite(SINFORMER_ETUDES_SUP_ID),
    ],
    bilanQuestions: [
      {
        id: 'citer-principales-formations',
        text: 'Je peux citer les principales formations possibles après un bac.',
      },
      {
        id: 'citer-principales-depenses',
        text: "Je peux citer les principales dépenses d'un étudiant.",
      },
      {
        id: 'citer-aides',
        text: 'Je peux citer des aides auxquelles les étudiants peuvent avoir droit.',
      },
      {
        id: 'citer-exemples-logements',
        text: 'Je peux donner des exemples de logements possibles pour les étudiants.',
      },
    ],
  },
  {
    theme: 'etudes-sup',
    moduleId: COMPRENDRE_PARCOURSUP_ID,
    title: 'Les grandes étapes de Parcoursup',
    icon: ComprendreParcoursupIcon,
    intro:
      'Ce module te permettra de découvrir les grandes étapes de Parcoursup et d’apprendre à gérer ton temps pour construire ton projet d’orientation.',
    profDescription:
      'Ce module est une introduction à l’univers Parcoursup et permet aux lycéens de commencer à se familiariser avec les grandes étapes de l’orientation.',
    objectifPedagogique:
      'Avec ce module, les élèves prennent conscience qu’un projet d’orientation se construit pas à pas et dans le temps. Qu’il y a des temps de recherche d’informations et de réflexion, et des temps de prise de décision.',
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: 'Les grandes étapes Parcoursup',
        activiteId: 'grandes-etapes-parcoursup',
        profDescription:
          'Cette activité propose aux élèves de prendre connaissance des principales étapes sur Parcoursup pour mieux les aider à gérer leur projet d’orientation dans le temps.',
        description:
          'Cette activité te propose de découvrir les principales étapes pour construire tes choix d’orientation sur Parcoursup.',
        duration: 20,
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_GAME }],
        steps: [
          {
            title: "Gérer mon projet d'orientation dans le temps",
            component: GererMonProjet,
            hasOwnFooter: true,
          },
          {
            title: "Etapes de l'orientation",
            component: EtapesOrientation,
          },
          {
            title: 'Infographie Parcoursup',
            component: InfographieParcoursup,
          },
        ],
      },
      makeBilanActivite(COMPRENDRE_PARCOURSUP_ID),
    ],
    bilanQuestions: [
      {
        id: 'citer-etapes-parcoursup',
        text: 'Je peux citer les grandes étapes de Parcoursup',
      },
      {
        id: 'idee-prochaines-etapes-formation',
        text: "J'ai une idée des prochaines étapes pour commencer à préparer mes choix de formations.",
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: CONNAITRE_ENTREPRISE_ID,
    icon: ConnaitreEntrepriseIcon,
    title: 'Connaître l’entreprise et son organisation',
    intro:
      'Ce module te permettra de mieux connaître le monde de l’entreprise et son organisation.',
    profDescription:
      'Ce module permet aux lycéens de mieux connaître le monde de l’entreprise.',
    objectifPedagogique:
      'Les élèves pourront tester leurs connaissances sur les entreprises, leur fonctionnement et leur organisation.',
    activites: [
      {
        title: 'Je découvre l’entreprise',
        activiteId: 'decouvrir-lentreprise',
        duration: 40,
        profDescription:
          'L’activité a pour but de faire découvrir les entreprises aux élèves ainsi que leur mode de fonctionnement.',
        description:
          'Cette activité te permettra de découvrir le monde de l’entreprise de l’intérieur et de comprendre son organisation.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_GAME },
        ],
        steps: [
          {
            title: 'Quiz',
            component: QuizzEntreprise,
          },
          {
            title: 'Qu’est-ce qu’une entreprise ?',
            component: VideoDecouvrirEntreprise,
          },
          {
            title: 'Organigramme',
            component: OrganigrammeEntreprise,
          },
          {
            title: 'Témoignages de professionnels',
            component: TemoignagesPro,
          },
        ],
      },
      makeBilanActivite(CONNAITRE_ENTREPRISE_ID),
    ],
    bilanQuestions: [
      {
        id: 'citer-types-entreprises',
        text: "Je peux citer différents types d'entreprises.",
      },
      {
        id: 'citer-fonctions-entreprise',
        text: "Je peux citer les fonctions principales d'une entreprise.",
      },
    ],
    // requirements: [hasFinishedQuestionnaire],
  },
  {
    theme: 'insertion-pro',
    moduleId: DECOUVRIR_METIERS_ID,
    title: 'Découvrir des métiers',
    icon: MetiersIcon,
    intro:
      "Ce module te permettra d'approfondir tes connaissances sur certains métiers en lien avec ton secteur de formation.",
    profDescription:
      'Ce module permet aux lycéens de mieux connaître certains métiers en lien avec leur secteur de formation.',
    objectifPedagogique:
      'À travers ce module les élèves découvriront des métiers et apprendront à identifier les informations pertinentes sur ces métiers (missions, profils souhaités, compétences requises,...)',
    activites: [
      {
        title: "Je m'informe sur les métiers",
        activiteId: 'information-metiers',
        duration: 20,
        profDescription:
          'L’activité permettra de comprendre et identifier des métiers correspondants au secteur d’études dans lequel se situe l’élève.',
        description:
          'Découvrir des métiers présentés par des professionnels en activité.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
        ],
        steps: [
          {
            title: 'Vidéos témoignages',
            component: VideoDecouverteMetiers,
          },
          {
            title: 'Fiches métiers',
            component: FichesMetiers,
          },
        ],
      },
      makeBilanActivite(DECOUVRIR_METIERS_ID),
    ],
    bilanQuestions: [
      {
        id: 'decrire-missions-metier',
        text: 'Je peux décrire les missions principales du métier analysé durant ce module.',
      },
      {
        id: 'citer-competences-demandees-metier',
        text: 'Je peux citer les compétences demandées dans le métier analysé durant ce module.',
      },
    ],
    // requirements: [hasFinishedQuestionnaire],
  },
  {
    theme: 'insertion-pro',
    moduleId: DECOUVRIR_ALTERNANCE_APPRENTISSAGE_ID,
    title: "Découvrir l'alternance et l'apprentissage",
    icon: ApprentissageIcon,
    intro:
      "Ce module te permettra d'approfondir tes connaissances sur l'alternance.",
    profDescription:
      "Ce module permet aux élèves de découvrir la notion d’alternance et d'approfondir leurs connaissances sur ce type de formation. ",
    objectifPedagogique:
      'L’objectif de ce module est de mieux appréhender l’univers de l’alternance et de faire la distinction entre alternance et apprentissage.',
    activites: [
      {
        title: "Le point sur l'alternance",
        activiteId: 'point-alternance',
        duration: 15,
        profDescription:
          'A travers une infographie et un débat, vous accompagnerez les élèves à mieux comprendre l’alternance et à déconstruire les idées reçues.',
        description:
          'Avec cette activité, tu découvriras plus en profondeur ce qu’est l’alternance.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
          { type: ACTIVITE_CONTENT_TYPE_GAME },
        ],
        steps: [
          {
            title: 'Approfondir la notion d’alternance',
            component: ApprofondirNotionAlternance,
            profInstructions:
              "Pour mieux comprendre les réponses du quiz, les élèves poursuivent l'exercice en recherchant les informations sur l’alternance à l’aide de l’infographie.",
          },
          {
            title: "Approfondir la notion d'alternance",
            component: AlternancesQuestionsOrales,
          },
        ],
      },
      {
        title: "Les avantages de l'apprentissage",
        activiteId: 'avantages-apprentissage',
        duration: 45,
        profDescription:
          'A travers des témoignages vidéos et une infographie, les élèves découvriront les avantages de l’alternance.',
        description:
          'Avec cette activité et à travers des témoignages, tu découvriras les avantages de faire une formation en alternance.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_GAME },
        ],
        steps: [
          {
            title: "Définition de l'apprentissage",
            component: DefinitionApprentissage,
          },
          {
            title: 'Vidéos témoignages d’apprentis',
            component: TemoignageNaomi,
          },
          {
            title: 'Vidéos témoignages d’apprentis',
            component: TemoignageEric,
          },
          {
            title: 'Vidéos témoignages d’apprentis',
            component: TemoignagePaolandrea,
          },
          {
            title: 'Infographie sur les chiffres clés de l’apprentissage',
            component: InfographieChiffresApprentissage,
          },
          {
            title: 'Les étapes avant d’entrer en apprentissage',
            component: LesEtapesAvantApprentissage,
          },
          {
            title: 'Les étapes avant d’entrer en alternance',
            component: InfographieEtapesAlternance,
          },
        ],
      },
      makeBilanActivite(DECOUVRIR_ALTERNANCE_APPRENTISSAGE_ID),
    ],
    bilanQuestions: [
      {
        id: 'definir-alternance',
        text: "Je peux définir ce qu'est l'alternance.",
      },
      {
        id: 'avantages-alternance',
        text: "Je peux citer les avantages de l'alternance.",
      },
      {
        id: 'etapes-alternance',
        text: 'Je peux citer les étapes pour entrer en alternance.',
      },
    ],
    // requirements: [hasFinishedQuestionnaire],
  },
  {
    theme: 'insertion-pro',
    moduleId: 'debattre-alternance',
    title: "Débattre sur l'alternance",
    icon: DebatIcon,
    intro: 'Ce module te permet de débattre sur l’alternance.',
    profDescription:
      'Ce module permet aux élèves de se questionner sur l’alternance à travers un débat collectif',
    objectifPedagogique: 'Déconstruire les idées reçues sur l’alternance.',
    activites: [
      {
        activiteId: 'alternance-en-debat',
        title: "L'alternance en débat",
        duration: 25,
        component: DebatAlternance,
        description: 'Avec cette activité, questionne-toi sur l’alternance.',
        profDescription:
          'Cette activité propose des questions sur l’alternance pour lever les idées reçues.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_ORAL }],
      },
    ],
    // requirements: [hasFinishedQuestionnaire],
  },
  {
    theme: 'insertion-pro',
    title: 'Connaître mes compétences acquises',
    moduleId: CONNAITRE_MES_COMPETENCES_ACQUISES_ID,
    icon: CompetencesAcquisesIcon,
    intro:
      'Ce module te permettra d’identifier les compétences développées durant un stage et de savoir les exprimer.',
    profDescription:
      'Ce module permet aux lycéens d’analyser les soft skills / compétences transversales acquises durant leur stage et d’apprendre à les valoriser.',
    objectifPedagogique:
      'L’objectif est d’être capable d’identifier ses compétences développées durant un stage et de savoir en parler.',
    activites: [
      {
        title: 'Parcours inspirant',
        activiteId: 'parcours-inspirant',
        description: `
          Avec ces témoignages, tu verras que ton parcours n’est pas forcément tracé d’avance
          et que chacune de tes expériences (bonnes ou mauvaises) peuvent t’apporter quelque chose
        `,
        profDescription: `
          Deux témoignages pour ouvrir l'horizon des possibles et se dire que son parcours
          n’est pas tracé d’avance et que les élèves peuvent faire de chacune de leurs expériences une force.
      `,
        duration: 20,
        steps: [
          {
            title: 'Un parcours linéaire ?',
            component: ParcoursLineaire,
          },
          {
            title: 'Les bénéfices de l’alternance',
            component: BeneficesAlternance,
          },
        ],
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
        ],
      },
      {
        title: 'Mon expérience de stage',
        activiteId: 'experience-de-stage',
        duration: 25,
        description:
          "T’aider à mettre en avant tes forces et tes axes d'amélioration suite à tes stages",
        profDescription:
          'L’activité a pour but d’aider le lycéen à faire ressortir de son expérience de stage ses forces et ses axes d’amélioration.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        steps: [
          {
            title: 'Définition des soft skills',
            component: DefinitionSoftSkills,
          },
          {
            title: 'Experience de stage',
            component: DecrisExperienceSoftSkills,
            hasOwnFooter: true,
          },
          {
            title: 'Ressenti pendant le stage',
            component: SelectRessentiSoftSkills,
            hasOwnFooter: true,
          },
          {
            title: 'Mission de stage',
            component: DecrisActionsSoftSkills,
            hasOwnFooter: true,
          },
          {
            title: 'Les softs skills développées',
            component: SelectSoftSkills,
            hasOwnFooter: true,
          },
          {
            title: 'Les difficultés rencontrées durant le stage',
            component: SelectDifficultesSoftSkills,
            hasOwnFooter: true,
          },
          {
            title: 'Les points à améliorer',
            component: DecrisAmeliorationsSoftSkills,
            hasOwnFooter: true,
          },
          {
            title: "Recap' de mon expérience de stage",
            component: MyRecapSoftSkills,
          },
          {
            title: 'Identifier ses compétences développées en stage',
            component: IdentifierSoftSkills,
          },
        ],
      },
      {
        title: "Mon plan d'action",
        activiteId: 'mon-plan-daction',
        duration: 10,
        description:
          'Avec cette activité, les élèves réfléchissent à un objectif à atteindre et les étapes pour y arriver.',
        profDescription:
          'L’activité a pour but d’aider le lycéen à comprendre sa progression après les stages réalisés et à mieux penser son projet personnel.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        steps: [
          {
            title: "Plan d'action",
            component: PlanActionObjectif,
            hasOwnFooter: true,
          },
          {
            title: "Plan d'action",
            component: PlanActionTemps,
            hasOwnFooter: true,
          },
          {
            title: "Plan d'action",
            component: PlanActionEntreprendre,
            hasOwnFooter: true,
          },
          {
            title: "Recap Plan d'action",
            component: MyRecapPlanAction,
          },
        ],
      },
      makeBilanActivite(CONNAITRE_MES_COMPETENCES_ACQUISES_ID),
    ],
    bilanQuestions: [
      {
        id: 'decrire-experience-stage',
        text: 'Je peux décrire une expérience de stage que j’ai eu.',
      },
      {
        id: 'citer-competences-mobilisees-stage',
        text: 'Je peux citer des compétences mobilisées lors de ce stage.',
      },
      {
        id: 'difficultes-rencontrees-stage',
        text: 'Je peux citer des difficultés rencontrées en stage.',
      },
      {
        id: 'fixe-objectif-amelioration-stage',
        text: 'Je me suis fixé un objectif à atteindre pour m’améliorer en stage.',
      },
    ],
    // requirements: [hasFinishedQuestionnaire],
  },
  {
    theme: 'insertion-pro',
    moduleId: CVDESIGNR_PREMIERE,
    title: 'Rediger son CV et sa lettre de motivation avec CVDesignR',
    icon: PersonDocumentIcon,
    intro:
      'Ce module a pour objectif de te permettre de rédiger un CV et une lettre de motivation adaptés à l’offre et à l’entreprise à laquelle tu postules.',
    profDescription:
      'Ce module a pour objectif de permettre aux élèves de rédiger un CV et une lettre de motivation qui soient adaptés à l’offre et à l’entreprise à laquelle ils postulent.',
    objectifPedagogique:
      "Les élèves sont capables de rédiger un CV, une lettre de motivation et de les adapter à l'offre et à l'entreprise",
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: 'Je réalise mon CV et ma LM sur CVDesignR',
        activiteId: 'cvdesignr-premiere-activite',
        description:
          'Cette activité te permet de choisir un modèle de CV qui te plait, de la compléter et de la faire corriger par ton enseignant. Tu peux également compléter une lettre de motivation et la faire corriger.',
        profDescription:
          'L’activité a pour but de sélectionner un modèle de CV, puis de le rédiger à l’aide de la trame proposée et enfin de le faire corriger par l’enseignant. Les élèves pourront également compléter une trame de lettre de motivation et la faire corriger.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM, count: 1 }],
        duration: 120,
        component: Cvdesignr,
      },
      makeBilanActivite(CVDESIGNR_PREMIERE),
    ],
    bilanQuestions: [
      {
        id: 'capable-rediger-cv-premiere',
        text: 'Je me sens capable de rédiger un CV.',
      },
      {
        id: 'capable-rediger-lm-premiere',
        text: 'Je me sens capable de rédiger une LM.',
      },
      {
        id: 'capable-adapter-cv-lm-entreprise-premiere',
        text: "Je me sens capable d'adapter mon CV et ma LM à l'offre et à l'entreprise.",
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: PREPARER_ENTRETIENS_ID,
    title: 'Se préparer pour ses entretiens',
    icon: MondeProIcon,
    intro: 'Cette activité te permet de préparer tes entretiens d’embauche',
    profDescription:
      'Cette activité permet de préparer des entretiens d’embauche et de mettre les élèves en situation.',
    objectifPedagogique:
      'L’objectif de ce module est de familiariser les élèves aux éléments de langage professionnel, et de comprendre l’enjeu de bien préparer un entretien d’embauche.',
    activites: [
      {
        title: "L'importance de la communication non verbale",
        activiteId: 'importance-communication-non-verbale',
        duration: 10,
        description:
          'Cette activité te permet de voir l’importance de son attitude et de sa posture lors des entretiens.',
        profDescription:
          'Cette activité permet de souligner l’importance de la communication non verbale lors des entretiens.',
        component: CommunicationNonVerbale,
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_GAME }],
      },
      {
        title: "Simulation d'entretien",
        activiteId: "Simulation d'entretien",
        duration: 30,
        profDescription:
          'L’activité a pour but d’aider le lycéen à se préparer à ses futurs entretiens d’embauche dans le cadre de leurs recherches de stage.',
        description:
          "Cette activité te permet de te préparer pour un entretien d'embauche.",
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_FORM },
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
        ],
        steps: [
          {
            title: 'Rappel sur les compétences',
            component: RappelCompetences,
            hasOwnFooter: true,
          },
          {
            title: 'Rappel sur les compétences',
            component: ListCompetences,
            hasOwnFooter: true,
          },
          {
            title: 'Recap compétences',
            component: MyRecapCompetences,
          },
          {
            title: 'Etape simulation entretien',
            component: SimulationEntretien,
          },
          {
            title: 'Etape simulation entretien',
            component: SimulationEntretienFeedback,
            hasOwnFooter: true,
          },
        ],
      },
      {
        title: 'Conseils pour réussir mon entretien',
        activiteId: 'conseils-pour-reussir-mon-entretien',
        duration: 10,
        description:
          "Cette activité te propose des anecdotes d'entretiens et des conseils pour réussir son entretien.",
        profDescription:
          "Cette activité propose des anecdotes d'entretiens et des conseils pour réussir son entretien.",
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
        ],
        steps: [
          {
            title: 'Témoignage anecdotes',
            component: TemoignagesAnecdotes,
          },
          {
            title: 'Témoignages Professionnels RH',
            component: TémoignagesProRH,
          },
          {
            title: 'Infographie Conseils Entretien Embauche',
            component: InfographieConseilEntretien,
          },
        ],
      },
      makeBilanActivite(PREPARER_ENTRETIENS_ID),
    ],
    bilanQuestions: [
      {
        id: 'definir-communication-non-verbale',
        text: 'Je peux définir la communication non-verbale.',
      },
      {
        id: 'infos-importantes-preparer-entretien',
        text: "Je peux citer les informations importantes à préparer avant d'aller en entretien.",
      },
      {
        id: 'capable-adopter-comportement-pro',
        text: "Je me sens capable d'adopter un comportement professionnel en entretien.",
      },
    ],
    // requirements: [hasFinishedQuestionnaire],
  },
];

export default modules;
