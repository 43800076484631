import { pick } from 'lodash';
import algoliasearch, {
  SearchClient as AlgoliaSearchClient,
} from 'algoliasearch';
import { Piste as _Piste, Article as _Article, User } from 'lib/generated';

interface ArticleWithSnippet extends Omit<_Article, '_id'> {
  _id: string;
  snippet?: string;
}

interface PisteWithSnippet extends Omit<_Piste, '_id'> {
  _id: string;
  snippet?: string;
}

interface AlgoliaSearchResult {
  objectID: string;
}

interface AutocompleteResults {
  pistes: PisteWithSnippet[];
  articles: ArticleWithSnippet[];
  eclaireurs: User[];
}

export interface SearchResults<T> {
  items: T[];
  page: number;
  numberOfPages: number;
}

interface TargetIndex {
  indexName: string;
  filters?: string;
}

export enum SearchIndex {
  Pistes = 'pistes',
  Articles = 'articles',
  Eclaireurs = 'eclaireurs',
}

type ResultEntity = PisteWithSnippet | ArticleWithSnippet | User;

interface AlgoliaIndexSearchResult {
  index: string;
  hits: object[];
}

const injectEclaireurRole = (eclaireur: User): User =>
  Object.assign({}, eclaireur, {
    profile: Object.assign(eclaireur.profile, { role: 'eclaireur' }),
  });

class SearchClient {
  private algoliaClient: AlgoliaSearchClient;
  private indexPrefix: string;

  constructor(appId: string, searchKey: string, indexPrefix: string) {
    this.algoliaClient = algoliasearch(appId, searchKey);
    this.indexPrefix = indexPrefix;
  }

  private extractSearchResults(
    results: AlgoliaIndexSearchResult[],
    index: SearchIndex
  ) {
    return results.find((r) => r.index.match(index)) || { hits: [] };
  }

  private mapSearchResults(
    results: object[],
    index: SearchIndex
  ): ResultEntity[] {
    const mapper = {
      [SearchIndex.Pistes]: [
        'name',
        'slug',
        'title',
        'content',
        'coverPicture',
      ],
      [SearchIndex.Articles]: [
        'slug',
        'title',
        'content',
        'coverPicture',
        'tags',
      ],
      [SearchIndex.Eclaireurs]: ['profile', 'isProfileComplete'],
    }[index];
    return results
      .map(
        (result) =>
          Object.assign(
            { _id: (result as AlgoliaSearchResult).objectID },
            pick(result, mapper)
          ) as ResultEntity
      )
      .map((r) =>
        index === SearchIndex.Eclaireurs ? injectEclaireurRole(r) : r
      );
  }

  private extractAndMapSearchResults(
    results: AlgoliaIndexSearchResult[],
    index: SearchIndex
  ) {
    return this.mapSearchResults(
      this.extractSearchResults(results, index).hits,
      index
    );
  }

  async search<T>(props: {
    indexName: string;
    query: string;
    filters?: string;
    page: number;
    hitsPerPage?: number;
    mapper: (hits: object[]) => T[];
  }): Promise<SearchResults<T>> {
    const { query, filters, page, hitsPerPage, indexName, mapper } = props;

    const index = this.algoliaClient.initIndex(
      this.resolveIndexName(indexName)
    );
    const result = await index.search(query, {
      filters,
      hitsPerPage: hitsPerPage || 10,
      page,
    });
    const { hits, nbPages: numberOfPages } = result;

    return {
      items: mapper(hits),
      page,
      numberOfPages,
    };
  }

  async searchForArticles(props: {
    query: string;
    page: number;
  }): Promise<SearchResults<ArticleWithSnippet>> {
    const { query, page } = props;
    return this.search<ArticleWithSnippet>({
      indexName: 'articles',
      query,
      filters: 'NOT deleted:true',
      page,
      hitsPerPage: 12,
      mapper: (hits) => {
        return this.mapSearchResults(hits, SearchIndex.Articles).map(
          (article, i) => {
            const hit = hits[i] as {
              _snippetResult?: { content?: { value?: string } };
            };
            return Object.assign({}, article, {
              snippet: hit._snippetResult?.content.value,
            });
          }
        );
      },
    });
  }

  async searchForPistes(props: {
    query: string;
    page: number;
  }): Promise<SearchResults<PisteWithSnippet>> {
    const { query, page } = props;
    return this.search<PisteWithSnippet>({
      indexName: 'pistes',
      query,
      filters: 'NOT deleted:true AND NOT draft:true',
      page,
      hitsPerPage: 12,
      mapper: (hits) => {
        return this.mapSearchResults(hits, SearchIndex.Pistes).map(
          (piste, i) => {
            const hit = hits[i] as {
              _snippetResult?: { content?: { value?: string } };
            };
            return Object.assign({}, piste, {
              snippet: hit._snippetResult?.content.value,
            });
          }
        );
      },
    });
  }

  async searchForEclaireurs(props: {
    query: string;
    page: number;
  }): Promise<SearchResults<User>> {
    const { query, page } = props;
    return this.search<User>({
      indexName: 'eclaireurs',
      query,
      filters: 'isProfileComplete:true AND profile.status:actif',
      page,
      hitsPerPage: 12,
      mapper: (hits) => this.mapSearchResults(hits, SearchIndex.Eclaireurs),
    });
  }

  async autocomplete(
    query: string,
    indices: SearchIndex[],
    minQuerySize = 2
  ): Promise<AutocompleteResults> {
    if (query.length < minQuerySize) {
      return {
        pistes: [],
        eclaireurs: [],
        articles: [],
      };
    }

    const targetIndices: TargetIndex[] = [
      {
        indexName: SearchIndex.Pistes,
        filters: 'NOT deleted:true AND NOT draft:true',
      },
      { indexName: SearchIndex.Articles, filters: 'NOT deleted:true' },
      {
        indexName: SearchIndex.Eclaireurs,
        filters: 'isProfileComplete:true AND profile.status:actif',
      },
    ].filter(({ indexName }) => indices.indexOf(indexName) !== -1);
    const queries = targetIndices.map(({ indexName, filters }) => ({
      indexName: this.resolveIndexName(indexName),
      query,
      filters,
      params: {
        hitsPerPage: 5,
      },
    }));

    return this.algoliaClient.multipleQueries(queries).then(({ results }) => {
      return {
        pistes: this.extractAndMapSearchResults(
          results as AlgoliaIndexSearchResult[],
          SearchIndex.Pistes
        ),
        articles: this.extractAndMapSearchResults(
          results as AlgoliaIndexSearchResult[],
          SearchIndex.Articles
        ),
        eclaireurs: this.extractAndMapSearchResults(
          results as AlgoliaIndexSearchResult[],
          SearchIndex.Eclaireurs
        ),
      };
    });
  }

  resolveIndexName(baseName: string): string {
    return `${this.indexPrefix}_${baseName}`;
  }
}

let searchClient;

export const getSearchClient = (): SearchClient => {
  if (!searchClient) {
    if (
      !process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ||
      !process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY ||
      !process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX
    ) {
      console.debug(`
        NEXT_PUBLIC_ALGOLIA_APP_ID: ${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID} \n
        NEXT_PUBLIC_ALGOLIA_SEARCH_KEY: ${process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY} \n
        NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX: ${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX} \n
      `);
      throw Error('Angolia: Missing environment variable');
    }

    searchClient = new SearchClient(
      process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
      process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
      process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX
    );
  }
  return searchClient;
};
