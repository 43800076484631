import {
  Box,
  Flex,
  Icon,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

import { ExternalIcon } from '@inspire/ui/icons';
import { cdnURL } from '@inspire/util/cdn';

import Link from 'components/general/Link';
import ProfTips from 'components/activites/ProfTips';

const fiches = [
  {
    url: cdnURL('/bac-pro/Fiche+me%CC%81tier+-+Agent+immobilier.pdf'),
    label: 'Agent.e immobilier.ère',
  },
  {
    url: cdnURL('/bac-pro/Fiche+me%CC%81tier+assistant+juridique.pdf'),
    label: 'Assistant.e juridique',
  },
  {
    url: cdnURL('/bac-pro/Fiche+me%CC%81tier+-+commercial.pdf'),
    label: 'Commercial.e',
  },
  {
    url: cdnURL('/bac-pro/Fiche+me%CC%81tier+Comptable.pdf'),
    label: 'Comptable',
  },
  {
    url: cdnURL('/bac-pro/Fiche+me%CC%81tier+courtier+en+banque.pdf'),
    label: 'Courtier.ère en banque',
  },
  {
    url: cdnURL('/bac-pro/Fiche+me%CC%81tier+Epicier+-+Commerc%CC%A7ant.pdf'),
    label: 'Commerçant.e en épicierie',
  },
  {
    url: cdnURL('/bac-pro/Fiche+metier+Ho%CC%82te+d_acceuil.pdf'),
    label: "Hôte.esse d'accueil",
  },
  {
    url: cdnURL('/bac-pro/Fiche+metier+Technicien+logistique.pdf'),
    label: 'Technicien.ne logistique',
  },
];

const FichesMetiers = () => (
  <>
    <ProfTips>
      <Text>
        Parmi les 8 fiches métiers proposées, vous pouvez sélectionner les 3 qui
        sont les plus pertinentes en fonction de la formation des élèves. Il
        s’agit, pour la majorité des fiches, des métiers présentés dans les
        vidéos témoignages précédentes.
      </Text>
      <Text>
        Proposition de questions à poser aux élèves sur les fiches métiers :
      </Text>
      <UnorderedList mb={0}>
        <ListItem>Quel type d’études doit-on faire pour devenir... ?</ListItem>
        <ListItem>
          Existe-t-il plusieurs parcours pour y parvenir ? Si oui lesquels ?
        </ListItem>
        <ListItem>
          Quelles sont les qualités requises pour le poste de... ?
        </ListItem>
      </UnorderedList>
    </ProfTips>
    <Text>
      À travers ces fiches métiers, découvre des informations sur les postes,
      les compétences requises, les parcours d’études souhaitables, ainsi que
      sur les rémunérations et évolutions possibles.
    </Text>
    <Text my={5}>
      <b>Note</b> : Sur mobile, il est possible que tu doives accepter de
      télécharger les fiches avant de les ouvrir.
    </Text>
    <SimpleGrid columns={[1, null, 2]} spacing={4}>
      {fiches.map((f) => (
        <Link
          key={f.url}
          target="_blank"
          rel="noopener noreferrer"
          textDecor="none"
          href={f.url}
          _hover={{ textDecor: 'none' }}
        >
          <Box shadow="sm" p={2} borderRadius={4} _hover={{ shadow: 'md' }}>
            <Flex align="center">
              <Icon mr={2} as={ExternalIcon} boxSize={5} />
              <Box>{f.label}</Box>
            </Flex>
          </Box>
        </Link>
      ))}
    </SimpleGrid>
  </>
);

export default FichesMetiers;
