import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const environment =
  process.env.NODE_ENV === 'development'
    ? 'development'
    : process.env.NEXT_PUBLIC_STAGE === 'local' &&
      process.env.NODE_ENV === 'production'
    ? 'local-prod'
    : process.env.NEXT_PUBLIC_STAGE;

if (environment !== 'development') {
  Sentry.init({
    environment,
    dsn: SENTRY_DSN,
    ignoreErrors: [
      'INTERNAL_SERVER_ERROR: No such email for user.',
      'INTERNAL_SERVER_ERROR: Email already exists.',
      'INTERNAL_SERVER_ERROR: Verify email link expired',
      'INTERNAL_SERVER_ERROR: Token expired',
      'INTERNAL_SERVER_ERROR: Not Authorised!',
      'BAD_SIGNUP_TOKEN: Wrong signup token',
      'ResizeObserver loop limit exceeded',
      'fbq is not defined',
      'ttq is not defined',
      "Can't find variable: fbq",
      "Can't find variable: ttq",
      'Failed to register a ServiceWorker',
      'sw.js load failed',
    ],
  });
}
