import { useEffect, useState, useRef } from 'react';

import {
  Box,
  Center,
  ListItem,
  Text,
  UnorderedList,
  Stack,
} from '@chakra-ui/react';
import Image from 'next/image';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import { FullVStack } from '@inspire/ui/chakra/layout/Stack';

import VideoPlayer from 'components/general/VideoPlayer';
import ProfTips from 'components/activites/ProfTips';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import ClickableImage from 'components/general/ClickableImage';
import { CheckboxGame } from 'components/activites/iterative-games';
import { NonFormikActiviteFooter } from 'components/activites/ActiviteFooter';
import ConsigneTag from 'components/activites/ConsigneTag';
import { useClasse, useMyProfile, useStepContext, useUser } from 'lib/hooks';

import InfographieSrcPro from '../../../../public/img/infographies/Infographie-schéma-post-bac-voie-pro.png';
import InfographieSrcAgro from '../../../../public/img/infographies/Infographie-schéma-post-bac-voie-agricole.png';
import parcoursupEtatBadgeSrc from '../../../../public/img/infographies/parcoursup-etat-badge.png';
import amphiSrc from '../../../../public/img/activites/formations-post-bac/amphi.jpg';
import classeSrc from '../../../../public/img/activites/formations-post-bac/classe.jpg';
import seuleSrc from '../../../../public/img/activites/formations-post-bac/eleve-seule.jpg';
import encadreSrc from '../../../../public/img/activites/formations-post-bac/eleves-encadres.jpg';
import elevePensiveSrc from '../../../../public/img/activites/formations-post-bac/eleve-pensive.jpg';
import revisionGroupeSrc from '../../../../public/img/activites/formations-post-bac/revision-groupe.jpg';
import elevesOralSrc from '../../../../public/img/activites/formations-post-bac/eleves-oral.jpg';
import tableSrc from '../../../../public/img/activites/formations-post-bac/table.jpg';

export const TemoignagesFormationsPostBac = () => (
  <>
    <ProfTips mb={3}>
      <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
      <UnorderedList>
        <ListItem>
          Utiliser un vidéoprojecteur pour une diffusion collective.
        </ListItem>
        <ListItem>
          Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
          sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </ListItem>
      </UnorderedList>
    </ProfTips>
    <ReflexionOralHeader />
    <Text fontStyle="italic">
      <ConsigneTag /> : Regarde les vidéos des différentes formations post-bac
      qui t’intéressent et réponds aux questions à l’oral.
    </Text>

    <FullVStack maxWidth="350px" margin="auto" spacing={5} mt={10}>
      <Box>
        <VideoPlayer
          url="/videos-bacpro/formations-post-bac-jean-baptiste.mp4"
          pt={1}
        />
        <Text textAlign="center" mt={2}>
          <b>Jean-Baptiste</b>, étudiant en licence universitaire
        </Text>
      </Box>
      <Box>
        <VideoPlayer url="/videos-bacpro/formations-post-bac-nathanel.mp4" />
        <Text textAlign="center" mt={2}>
          <b>Nathanel</b>, Mention complémentaire puis a repris ses études 3 ans
          après
        </Text>
      </Box>
      <Box>
        <VideoPlayer url="/videos-bacpro/formations-post-bac-lyvio.mp4" />
        <Text textAlign="center" mt={2}>
          <b>Lyvio</b>, étudiant en prépa professionnelle et école d’ingénieur
        </Text>
      </Box>
    </FullVStack>

    <Text>Questions :</Text>
    <UnorderedList>
      <ListItem>
        A quel moment ont-ils su ce qu’ils souhaitaient faire comme études ?
        Qu’est-ce qui a déterminé leur choix ?
      </ListItem>
      <ListItem>Ont-ils ressenti des difficultés ?</ListItem>
      <ListItem>
        Ont-ils réussi à aller au bout de leurs projets ou certains ont dû
        changer en cours de parcours ?
      </ListItem>
      <ListItem>
        Qu’est ce sont les passerelles ? Peux-tu donner un exemple de passerelle
        présentée dans les vidéos ?
      </ListItem>
    </UnorderedList>

    <ProfTips mt={10}>
      <Text>
        Les passerelles permettent aux étudiants de poursuivre des études dans
        un autre cursus ou dans un autre type d'études. Concrètement, une
        passerelle permet à un étudiant d'accéder directement à une année
        d'études autre que la première année. Il existe des passerelles dans
        quasiment toutes les formations.
      </Text>
      <Text>
        Par exemple : après une première année de licence validée, il est
        parfois possible d’entrer directement en deuxième année de BUT, si les
        secteurs sont relativement proches. Pour connaître les passerelles
        possibles, il faut se renseigner auprès de son établissement du
        supérieur.
      </Text>
    </ProfTips>
  </>
);

export const FormationsPostBacPasserelles = ({ allowNext }) => {
  const [isImageShown, setIsImageShown] = useState(true);
  const consigneRef = useRef(null);
  const { classeIsAgro } = useClasse();

  const questions = [
    {
      subject: 'Quelle formation post-bac en 2 ans se fait au lycée ?',
      answerValues: ['classe-prepa', 'bts'],
      options: [
        { shortName: 'Classe prépa', value: 'classe-prepa' },
        { shortName: 'BUT', value: 'but' },
        { shortName: 'Licence 2', value: 'licence-2' },
        { shortName: classeIsAgro ? 'BTS / BTSA' : 'BTS', value: 'bts' },
        { shortName: 'MC', value: 'mc' },
        { shortName: 'École post-bac', value: 'ecole-post-bac' },
      ],
    },
    {
      subject:
        'La licence professionnelle ne peut se faire qu’après une licence générale ?',
      answerValues: ['faux'],
      options: [
        { shortName: 'Vrai', value: 'vrai' },
        { shortName: 'Faux', value: 'faux' },
      ],
      extraSummaryInfo:
        'C’est possible après un BTS' +
        (classeIsAgro ? ', un BTSA' : '') +
        ' ou après 2 ans en BUT.',
    },
    {
      subject:
        'Après un BTS' +
        (classeIsAgro ? ' ou un BTSA' : '') +
        ', je peux rejoindre une licence ou une école ?',
      answerValues: ['vrai'],
      options: [
        { shortName: 'Vrai', value: 'vrai' },
        { shortName: 'Faux', value: 'faux' },
      ],
      extraSummaryInfo:
        "Les passerelles permettent aux étudiants de poursuivre des études dans un autre cursus ou dans un autre type d'études. Concrètement, une passerelle permet à un étudiant d'accéder directement à une année d'études autre que la première année. Il existe des passerelles dans quasiment toutes les formations, il faut toutefois que les domaines soient assez proches : on ne pourra pas passer du commerce à la biologie !",
    },
    {
      subject: 'Les passerelles se font toujours en fin d’année.',
      answerValues: ['faux'],
      options: [
        { shortName: 'Vrai', value: 'vrai' },
        { shortName: 'Faux', value: 'faux' },
      ],
      extraSummaryInfo:
        'Il est possible de changer d’avis et donc de voie en cours d’année. Pour cela il faut se renseigner auprès du service d’orientation de son établissement afin de connaître les passerelles possibles dès le 1er semestre.',
    },
    {
      subject: 'Les écoles post-bac sont toutes payantes.',
      answerValues: ['faux'],
      options: [
        { shortName: 'Vrai', value: 'vrai' },
        { shortName: 'Faux', value: 'faux' },
      ],
      extraSummaryInfo:
        'Certaines écoles sont publiques et gratuites par exemple les écoles militaires, certaines écoles d’ingénieurs ou encore de communication. De plus, dans certaines écoles privées les étudiants boursiers n’ont pas à payer les frais de scolarité.',
    },
    {
      subject:
        'La classe prépa est la seule voie pour intégrer une école de commerce ou d’ingénieurs.',
      answerValues: ['faux'],
      options: [
        { shortName: 'Vrai', value: 'vrai' },
        { shortName: 'Faux', value: 'faux' },
      ],
      extraSummaryInfo:
        'Certaines grandes écoles de commerce ou d’ingénieurs sont accessible après le bac ou après un bac + 2 autre qu’une classe prépa (DUT, BTS, L2).',
    },
    {
      subject: 'Tous les diplômes ne sont pas reconnus par l’Etat.',
      answerValues: ['vrai'],
      options: [
        { shortName: 'Vrai', value: 'vrai' },
        { shortName: 'Faux', value: 'faux' },
      ],
      extraSummaryInfo: (
        <>
          <Box>
            Certains diplômes d’établissements privés ne sont pas reconnus par
            l’Etat. Il peuvent avoir une valeur sur le marché du travail, mais
            n’offrent pas d’équivalence permettant une poursuite d’études. Sur
            Parcoursup, il est indiqué pour chaque formation si elle est
            reconnue par l’Etat.
          </Box>
          <Center my={3}>
            <Box w="120px">
              <Image src={parcoursupEtatBadgeSrc} alt="Badge Etat Parcoursup" />
            </Box>
          </Center>
        </>
      ),
    },
    {
      subject:
        'Je peux terminer mes études supérieures dans un autre pays européen.',
      answerValues: ['vrai'],
      options: [
        { shortName: 'Vrai', value: 'vrai' },
        { shortName: 'Faux', value: 'faux' },
      ],
      extraSummaryInfo:
        'Le système dit “LMD” est une harmonisation européenne rendue possible par les crédits ECTS. Grâce à des programmes d’échanges et les accords entre pays, il est possible de commencer ses études dans un pays et de les finir dans un autre.',
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <>
      <Text fontStyle="italic" ref={consigneRef}>
        <ConsigneTag /> : Réponds aux questions en t’aidant du schéma des
        formations post-bac.
      </Text>

      {isImageShown && (
        <ClickableImage
          src={classeIsAgro ? InfographieSrcAgro : InfographieSrcPro}
          href={
            classeIsAgro
              ? '/img/infographies/Infographie-schéma-post-bac-voie-agricole.png'
              : '/img/infographies/Infographie-schéma-post-bac-voie-pro.png'
          }
          alt="Principales formations bac pro avec passerelles"
        />
      )}
      <CheckboxGame
        questions={questions}
        getOptions={(question) => question?.options}
        onNext={(index) => {
          if (index === 3) {
            setIsImageShown(false);
            consigneRef.current.scrollIntoView();
          }
        }}
        onFinished={() => allowNext(true)}
        getQuestionSection={(question) => (
          <Text mt={0}>
            <i>{question.subject}</i>
          </Text>
        )}
        getSummarySection={(_, questionIndex) =>
          questionIndex !== 0 && (
            <SummaryFrame mt={5}>
              <UnorderedList>
                {questions.slice(0, questionIndex).map((q) => (
                  <ListItem key={q.subject}>
                    <b>{q.subject} </b>{' '}
                    {q.answerValues
                      .map(
                        (av) => q.options.find((o) => o.value === av).shortName
                      )
                      .join(', ')}
                    {q.extraSummaryInfo && <Box>{q.extraSummaryInfo}</Box>}
                  </ListItem>
                ))}
              </UnorderedList>
            </SummaryFrame>
          )
        }
      />
    </>
  );
};

const PhotosStep = ({
  question,
  img1Src,
  img2Src,
  fieldName,
  img1Value,
  img2Value,
  img1Legend,
  img2Legend,
  img1Details,
  img2Details,
  credit,
}) => {
  const [answer, setAnswer] = useState(null);
  const { goNext } = useStepContext();
  const { isLyceen } = useUser();

  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, { onSuccess: goNext });

  useEffect(() => {
    if (myProfile?.[fieldName]) {
      setAnswer(myProfile[fieldName]);
    }
  }, [myProfile?.[fieldName]]);

  return (
    <>
      <Text fontStyle="italic">
        <ConsigneTag /> : Choisis l’option qui te correspond le plus en cliquant
        sur l'image de ton choix.
      </Text>
      <Text textAlign="center">{question}</Text>
      <Stack direction={['column', 'row']} spacing={5}>
        <Box cursor="pointer" onClick={() => setAnswer(img1Value)}>
          <Box
            borderRadius={4}
            lineHeight={0}
            overflow="hidden"
            filter={answer === img2Value && 'grayscale(1)'}
          >
            <Image src={img1Src} alt={img1Legend} />
          </Box>
          <Text
            textAlign="center"
            textColor={answer === img2Value && 'gray.500'}
            fontWeight={answer === img1Value && 'bold'}
          >
            {img1Legend}
          </Text>
        </Box>
        <Box cursor="pointer" onClick={() => setAnswer(img2Value)}>
          <Box
            borderRadius={4}
            lineHeight={0}
            overflow="hidden"
            filter={answer === img1Value && 'grayscale(1)'}
          >
            <Image src={img2Src} alt={img2Legend} />
          </Box>
          <Text
            textAlign="center"
            textColor={answer === img1Value && 'gray.500'}
            fontWeight={answer === img2Value && 'bold'}
          >
            {img2Legend}
          </Text>
        </Box>
      </Stack>
      {answer && (
        <Text>{answer === img1Value ? img1Details : img2Details}</Text>
      )}
      <Text fontSize="sm">Photos provenant de : {credit}</Text>
      <NonFormikActiviteFooter
        buttonProps={{
          isDisabled: isLyceen && !answer,
          isLoading: isSettingAndRefetchingMyProfile,
          onClick: () => {
            if (answer) {
              setAndRefetchMyProfile({ [fieldName]: answer });
            }
          },
        }}
      />
    </>
  );
};

const options = {
  amphi: 'En amphithéâtre (dis “amphi”, une très grande salle de cours)',
  classe: 'En petit groupe de classe, comme au lycée',

  encadre: 'Être encadré.e par les professeurs',
  autonome: 'Être peu encadré.e et travailler de manière autonome',

  groupe: 'Travailler en groupe',
  seul: 'Travailler seul.e',

  'beaucoup-cours-peu-devoirs': 'Beaucoup de cours et moins de devoirs maison',
  'beaucoup-devoirs-peu-cours': 'Peu de cours et beaucoup de devoirs maison',
};

export const FormatClassePhotosStep = () => {
  const { classeIsAgro } = useClasse();
  return (
    <PhotosStep
      question="Pour mes études supérieures, je préfère avoir des cours :"
      img1Src={amphiSrc}
      img2Src={classeSrc}
      fieldName="preferenceFormatClasse"
      img1Value="amphi"
      img2Value="classe"
      img1Legend={options.amphi}
      img2Legend={options.classe}
      img1Details={
        <>
          <strong>
            En licence, la plupart des cours se font en amphithéâtre et certains
            se font en TD (travaux dirigés), un format classe un peu comme au
            lycée.
          </strong>{' '}
          En BTS, {classeIsAgro ? 'BTSA,' : ''} BUT ou dans les formations en 1
          an (MC, FCIL{classeIsAgro ? ', CS' : ''}...), on trouve un format
          “classe” comme au lycée mais avec un peu plus d’élèves. En BUT il y a
          tout de même quelques cours en amphithéâtres."
        </>
      }
      img2Details={
        <>
          <strong>
            En BTS, {classeIsAgro ? 'BTSA,' : ''} BUT ou dans les formations en
            1 an (MC, FCIL{classeIsAgro ? ', CS' : ''}...), les cours se font
            principalement en format “classe” comme au lycée mais avec un peu
            plus d’élèves. En BUT il y a tout même quelques cours en
            amphithéâtres.
          </strong>{' '}
          En licence, la plupart des cours se font en amphithéâtre et certains
          cours se font en TD (travaux dirigés), un format classe un peu comme
          au lycée.
        </>
      }
      credit="Pexels"
    />
  );
};
export const EncadrementPhotosStep = () => {
  const { classeIsAgro } = useClasse();
  return (
    <PhotosStep
      question="Pour mes études supérieures, je préfère :"
      img1Src={encadreSrc}
      img2Src={seuleSrc}
      fieldName="preferenceEncadrement"
      img1Value="encadre"
      img2Value="autonome"
      img1Legend={options.encadre}
      img2Legend={options.autonome}
      img1Details={
        <>
          <strong>
            En BTS{classeIsAgro ? '/BTSA' : ''}, en classe préparatoire, ou dans
            les formations en 1 an, les professeurs seront plus présents pour
            accompagner les étudiants.
          </strong>{' '}
          En revanche, à l’université, on demande aux étudiants de travailler de
          manière autonome. Il y a moins de professeurs par classe et donc moins
          d’encadrement.
        </>
      }
      img2Details={
        <>
          <strong>
            A l’université, on demande aux étudiants de travailler de manière
            autonome. Il y a moins de professeurs par classe et donc moins
            d’encadrement.
          </strong>{' '}
          En revanche, en BTS{classeIsAgro ? '/BTSA' : ''}, en classe
          préparatoire, ou dans les formations en 1 an, les professeurs seront
          plus présents pour accompagner les étudiants.
        </>
      }
      credit="Freepik"
    />
  );
};

const revisionsSharedDetails = (
  <>
    Dans les études supérieures, tu pourras{' '}
    <strong>choisir les modes de révisions</strong> qui te conviennent le plus.
    Le plus important c’est d’<strong>anticiper</strong> et de s’
    <strong>organiser</strong>. La plupart des formations demandent à la fois de
    savoir <strong>travailler collectivement et individuellement</strong>.
  </>
);

export const RevisionsGroupePhotosStep = () => (
  <PhotosStep
    question="Pour réviser, je préfère :"
    img1Src={revisionGroupeSrc}
    img2Src={elevePensiveSrc}
    fieldName="preferenceRevisions"
    img1Value="groupe"
    img2Value="seul"
    img1Legend={options.groupe}
    img2Legend={options.seul}
    img1Details={revisionsSharedDetails}
    img2Details={revisionsSharedDetails}
    credit="Freepik"
  />
);

const emploiDuTempsSharedDetails = (
  <>
    Dans les études supérieures, il sera toujours nécessaire d’
    <strong>étudier/réviser en dehors des cours</strong>, toutefois les emplois
    du temps peuvent beaucoup varier d’une formation à l’autre. Attention au
    piège à l’université, il y a{' '}
    <strong>parfois peu de cours dans la semaine</strong>, mais cela demande
    beaucoup de travail personnel pour préparer les partiels, dès le début de
    l’année !
  </>
);

export const RepartitionDevoirsMaisonPhotosStep = () => (
  <PhotosStep
    question="Et pour mon emploi du temps, je préfère :"
    img1Src={elevesOralSrc}
    img2Src={tableSrc}
    fieldName="preferenceEmploiDuTemps"
    img1Value="beaucoup-cours-peu-devoirs"
    img2Value="beaucoup-devoirs-peu-cours"
    img1Legend={options['beaucoup-cours-peu-devoirs']}
    img2Legend={options['beaucoup-devoirs-peu-cours']}
    img1Details={emploiDuTempsSharedDetails}
    img2Details={emploiDuTempsSharedDetails}
    credit="Pexels"
  />
);

export const RecapRythmeEtudesSup = ({ profile }) => (
  <>
    <Text>Mes préférences de rythme des études supérieures :</Text>
    <Text>
      <b>Format de classe</b> : {options[profile.preferenceFormatClasse]}
    </Text>
    <Text>
      <b>Encadrement</b> : {options[profile.preferenceEncadrement]}
    </Text>
    <Text>
      <b>Révisions</b> : {options[profile.preferenceRevisions]}
    </Text>
    <Text>
      <b>Emploi du temps</b> : {options[profile.preferenceEmploiDuTemps]}
    </Text>
  </>
);
